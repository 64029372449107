import { Stack } from "@mui/material";
import { EditDialog } from "@react-admin/ra-form-layout";
import {
  Labeled,
  ReferenceField,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  useRecordContext,
  useUpdate,
} from "react-admin";
import { FieldValues } from "react-hook-form";
import { rteCommunicationMarketStatusChoices } from "shared/enums/reactAdminChoices";
import { RteCommunicationV2ResourcesEnum, SiteResourcesEnum } from "shared/enums/resourceMappings";

const RteCommunicationMarketConfigEdit = () => {
  const record = useRecordContext();
  const [update] = useUpdate();

  const handleUpdate = ({ status }: FieldValues) => {
    if (!record) throw new Error("Must be called with a RecordContext");
    const data = { status };

    update(
      RteCommunicationV2ResourcesEnum.RteCommunicationMarketConfig,
      {
        id: record.id,
        data,
        previousData: record,
        meta: {
          method: "PATCH",
        },
      },
      {
        mutationMode: "pessimistic",
      },
    );
  };

  return (
    <EditDialog fullWidth maxWidth="sm" mutationMode="pessimistic">
      <SimpleForm onSubmit={handleUpdate}>
        <Stack spacing={4} direction={"row"} sx={{ p: 2 }}>
          <Labeled label="EDR">
            <ReferenceField source="edr_id" reference={SiteResourcesEnum.Groups} />
          </Labeled>
          <Labeled label="Market">
            <TextField source="market" />
          </Labeled>
        </Stack>
        <SelectInput source="status" choices={rteCommunicationMarketStatusChoices} validate={[required()]} />
      </SimpleForm>
    </EditDialog>
  );
};

export default RteCommunicationMarketConfigEdit;
