import { Grid } from "@mui/material";
import { Labeled, NumberField, Show, SimpleShowLayout, TextField } from "react-admin";
import CustomDateField from "shared/components/fields/CustomDateField";

export default function CertificationsTestsShow() {
  return (
    <Show>
      <SimpleShowLayout>
        <Grid item xs={4}>
          <Labeled source="start_date">
            <CustomDateField source="start_date" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="end_date">
            <CustomDateField source="end_date" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="fcr_engagement">
            <TextField source="fcr_engagement" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="afrr_engagement">
            <TextField source="afrr_engagement" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="group_code">
            <TextField source="group_code" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="certif_id" label={"Certification"}>
            <NumberField source="certif_id" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="test_type">
            <TextField source="test_type" />
          </Labeled>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
}
