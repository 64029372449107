import { Datagrid, ListContextProvider, NumberField, SortPayload, TextField, useGetList, useList } from "react-admin";
import CustomDateField from "shared/components/fields/CustomDateField";
import { AfrrEngagementResourcesEnum } from "shared/enums/resourceMappings";

type PointsInRangeListProps = {
  filter: any;
};

export default function PointsInRangeList({ filter }: PointsInRangeListProps) {
  const { data } = useGetList(AfrrEngagementResourcesEnum.Point, {
    filter,
    pagination: { page: 1, perPage: 100 },
    sort: { field: "id", order: "ASC" } as SortPayload,
  });
  const listContext = useList({ data: data ? data.filter((item) => item.status !== "OBSOLETE") : [] });
  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        <CustomDateField showTime source="start_date" />
        <CustomDateField showTime source="end_date" />
        <TextField source="status" />
        <TextField source="direction" />
        <NumberField source="offered_quantity" />
        <NumberField source="offered_price" />
        <NumberField source="accepted_quantity" />
        <NumberField source="accepted_price" />
      </Datagrid>
    </ListContextProvider>
  );
}
