import { Box, Divider, Stack, Typography } from "@mui/material";
import axios from "axios";
import { MaintenanceChip } from "entities/maintenances/components/MaintenanceChip";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Datagrid,
  FunctionField,
  Labeled,
  Loading,
  RaRecord,
  ReferenceArrayField,
  ReferenceField,
  SortPayload,
  TextField,
  useGetList,
  useRecordContext,
} from "react-admin";
import { GenericColorChip } from "shared/components/chips/GenericColorChip";
import CustomBooleanField from "shared/components/fields/CustomBooleanField";
import CustomDateField from "shared/components/fields/CustomDateField";
import IEChargeIcon from "shared/components/icons/IEChargeIcon";
import { FormattedGroupTypeEnum } from "shared/enums/globalEnums";
import { MaintenanceResourcesEnum, SiteResourceMapping, SiteResourcesEnum } from "shared/enums/resourceMappings";
import { GroupeModeColorMapping } from "shared/enums/colorMappings/globalColorMappings";
import PlanningMaskField from "../PlanningMaskField";

export default function GroupBasicInfos() {
  const record = useRecordContext<RaRecord>();
  const type = record?.type;
  const formattedType = type ? FormattedGroupTypeEnum[type as keyof typeof FormattedGroupTypeEnum] : "";
  return (
    <Box p={2}>
      <Stack direction={"row"} spacing={4}>
        <Labeled>
          <TextField source="name" />
        </Labeled>
        <Labeled label={`Code ${formattedType}`}>
          <TextField source="code" />
        </Labeled>
        <Labeled label={`Code Tranche TM ${formattedType}`}>
          <TextField source="code_tm" />
        </Labeled>
        <Labeled>
          <GenericColorChip source="mode" colorMapping={GroupeModeColorMapping} />
        </Labeled>
        <Labeled source={"current_planning_mask"}>
          <PlanningMaskField source="current_planning_mask" />
        </Labeled>
      </Stack>
      <Divider sx={{ p: 2 }} />
      <Stack>
        <Labeled source={"Sites"} m={2}>
          <ReferenceArrayField source="site_ids" reference={SiteResourcesEnum.Sites} label="Sites">
            <StatusList />
          </ReferenceArrayField>
        </Labeled>
      </Stack>
    </Box>
  );
}

const StatusList = () => {
  const record = useRecordContext<{ site_ids: string[] }>();

  const { site_ids } = record || {};

  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchStatuses = useCallback(async (site_id: number) => {
    setLoading(true);
    const { data } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_SITE_API_URL}/${SiteResourceMapping[SiteResourcesEnum.Statuses]}`,
      params: {
        page: 1,
        size: 1,
        site_id,
        order_by: "-timestamp",
      },
    });
    return data.items;
  }, []);

  useEffect(() => {
    if (!site_ids || site_ids.length === 0) {
      setStatuses([]);
      setLoading(false);
      return;
    }

    let isMounted = true; // Use a flag to avoid state updates on unmounted components

    const calls = site_ids.map((site_id: string) => fetchStatuses(Number(site_id)));

    Promise.allSettled(calls)
      .then((results) => {
        if (!isMounted) return; // Prevent setting state if component is unmounted
        let fetchedStatuses: any = [];
        results.forEach((result) => {
          if (result.status === "fulfilled" && result.value) {
            fetchedStatuses = [...fetchedStatuses, ...result.value];
          }
        });
        setStatuses(fetchedStatuses);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });

    return () => {
      isMounted = false; // Cleanup logic
    };
  }, [site_ids, fetchStatuses]);

  return (
    <Datagrid data={statuses} total={statuses.length} isLoading={loading} bulkActionButtons={false}>
      <ReferenceField source="site_id" reference={SiteResourcesEnum.Sites} link="show" />
      <CustomDateField source="timestamp" locales={"fr-FR"} showTime label={"Last Status Timestamp"} />
      <TextField source="site.rte_code" label="Code TM site" />
      <FunctionField
        source="Iecharge"
        label={"IECharge"}
        render={(record: RaRecord) => {
          return record.site.iecharge ? <IEChargeIcon /> : null;
        }}
      />
      <CustomBooleanField source="network" />
      <CustomBooleanField source="control" />
      <CustomBooleanField source="measure" />
      <CustomBooleanField source="sequence" />
      <CustomBooleanField source="power_clamping" />
      <CustomBooleanField source="aggregator_module" />
      <FunctionField
        source="maintenance"
        render={(record: RaRecord) => {
          return <SiteMaintenanceField record={record} />;
        }}
      />
    </Datagrid>
  );
};

const SiteMaintenanceField = ({ record }: { record: RaRecord }) => {
  const now = useMemo(() => new Date().toISOString(), []);

  const { data, isLoading, error } = useGetList(MaintenanceResourcesEnum.Maintenance, {
    sort: { field: "start", order: "ASC" } as SortPayload,
    pagination: { page: 1, perPage: 1 },
    filter: {
      site_id: record.site_id,
      start__lte: now,
      end__gte: now,
    },
  });

  if (isLoading) return <Loading />;
  if (error) return <Typography color={"error"}>Error loading maintenance</Typography>;
  if (!data || data.length === 0) return <MaintenanceChip />;

  const maintenance = data[0];
  return <MaintenanceChip label={maintenance.type} color={"warning"} />;
};
