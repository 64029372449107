import { addRefreshAuthToDataProvider, Admin, Resource } from "react-admin";

import { BrowserRouter } from "react-router-dom";

import { GroupCreate } from "entities/site_service/groups/GroupCreate";
import { GroupEdit } from "entities/site_service/groups/GroupEdit";
import { GroupShow } from "entities/site_service/groups/GroupShow";
import { GroupList } from "entities/site_service/groups/list/GroupList";

import { UserCreate } from "entities/users/UserCreate";
import { UserEdit } from "entities/users/UserEdit";
import { UserShow } from "entities/users/UserShow";
import { UsersList } from "entities/users/UsersList";

import { StatusEdit } from "entities/site_service/sites_statuses/StatusEdit";
import { StatusShow } from "entities/site_service/sites_statuses/StatusShow";

import { BidsList } from "entities/regelleistung_service/bids/BidsList";

import PlanningMaskCreate from "entities/planning_service/plannings_masks/PlanningMaskCreate";

import { getPermissionsFromAccount, loginRequest, MSALObj, tokenRequest } from "app/providers/auth/authConfig";
import combinedDataProvider from "app/providers/data/compositeDataProvider";
import { i18nProvider } from "app/providers/i18n/i18nProvider";
import ReTheme from "app/providers/theme";
import { parseISO } from "date-fns";
import AfrrEngagementVisualization from "entities/affr_engagement_service/AfrrEngagementVisualization";
import { AfrrEngagementBidTimeserieShow } from "entities/affr_engagement_service/bidTimeSeries/AfrrEngagementBidTimeserieShow";
import { AfrrEngagementBidTimeSeriesList } from "entities/affr_engagement_service/bidTimeSeries/AfrrEngagementBidTimeSeriesList";
import AfrrEngagementPointList from "entities/affr_engagement_service/points/AfrrEngagementPointList";

import DeliveryDatePoints from "entities/ecp/DeliveryDatePoints";
import { MaintenanceCreate } from "entities/maintenances/MaintenancesCreate";
import { MaintenancesEdit } from "entities/maintenances/MaintenancesEdit";
import { MaintenancesList } from "entities/maintenances/MaintenancesList";
import { MaintenancesShow } from "entities/maintenances/MaintenancesShow";
import { EmaxHGateList } from "entities/mecapa/EmaxHGateList";
import { EmaxJGateList } from "entities/mecapa/EmaxJGateList";
import CertificationsCreate from "entities/optimizer/certifications/CertificationsCreate";
import CertificationsEdit from "entities/optimizer/certifications/CertificationsEdit";
import CertificationsList from "entities/optimizer/certifications/CertificationsList";
import CertificationsShow from "entities/optimizer/certifications/CertificationsShow";
import CertificationsTestsCreate from "entities/optimizer/certifications_tests/CertificationsTestsCreate";
import CertificationsTestsEdit from "entities/optimizer/certifications_tests/CertificationsTestsEdit";
import CertificationsTestsList from "entities/optimizer/certifications_tests/CertificationsTestsList";
import CertificationsTestsShow from "entities/optimizer/certifications_tests/CertificationsTestsShow";
import GroupsCertificationsCreate from "entities/optimizer/groups_certifications/GroupsCertificationsCreate";
import GroupsCertificationsList from "entities/optimizer/groups_certifications/GroupsCertificationsList";
import OptiTasksList from "entities/optimizer/tasks/OptiTasksList";
import PlanningCreate from "entities/planning_service/plannings/PlanningCreate";
import PlanningEdit from "entities/planning_service/plannings/PlanningEdit";
import PlanningShow from "entities/planning_service/plannings/PlanningShow";
import PlanningsList from "entities/planning_service/plannings/PlanningsList";
import { PlanningMaskList } from "entities/planning_service/plannings_masks/PlanningMaskList";
import RteCommunicationConfigList from "entities/rteCommunicationV2/configs/RteCommunicationConfigList";
import RteCommunicationFakePaList from "entities/rteCommunicationV2/fakePas/RteCommunicationFakePaList";
import RteCommunicationMarketConfigList from "entities/rteCommunicationV2/marketConfigs/RteCommunicationMarketConfigList";
import RteCommunicationTestConfigList from "entities/rteCommunicationV2/testConfigs/RteCommunicationTestConfigList";
import GenerationInitialForm from "entities/site_service/performances/dagGeneration/InitialForm";
import PerformanceList from "entities/site_service/performances/performanceValidation/PerformanceList";
import { SiteCreate } from "entities/site_service/sites/SiteCreate";
import { SiteEdit } from "entities/site_service/sites/SiteEdit";
import { SiteShow } from "entities/site_service/sites/SiteShow";
import { SiteList } from "entities/site_service/sites/SitesList";
import StatusList from "entities/site_service/sites_statuses/StatusList";
import { StatusesSimpleList } from "entities/site_service/sites_statuses/StatusesSimpleList";
import TopNiveauBidsCreate from "entities/topNiveau/bids/TopNiveauBidsCreate";
import TopNiveauBidsEdit from "entities/topNiveau/bids/TopNiveauBidsEdit";
import TopNiveauBidsList from "entities/topNiveau/bids/TopNiveauBidsList";
import TopNiveauBidsShow from "entities/topNiveau/bids/TopNiveauBidsShow";
import TopNiveauGatesCreate from "entities/topNiveau/gates/TopNiveauGatesCreate";
import TopNiveauGatesEdit from "entities/topNiveau/gates/TopNiveauGatesEdit";
import TopNiveauGatesList from "entities/topNiveau/gates/TopNiveauGatesList";
import TopNiveauGatesShow from "entities/topNiveau/gates/TopNiveauGatesShow";
import FakePasCreate from "entities/tsoTests/fake_pas/FakePasCreate";
import { FakePasList } from "entities/tsoTests/fake_pas/FakePasList";
import NlevelLossCreate from "entities/tsoTests/NLevelLoss/NlevelLossCreate";
import { NLevelLossList } from "entities/tsoTests/NLevelLoss/NLevelLossList";
import TestsAfrrRtePlanningsContainer from "entities/tsoTests/testsAfrrRte/TestsAfrrRtePlanningsContainer";
import TestsRtePlanningsContainer from "entities/tsoTests/testsFcrRte/TestsRtePlanningsContainer";
import TestFingridFcrN from "entities/tsoTests/testsFingrid/TestFingridFcrN";
import { msalAuthProvider, msalRefreshAuth } from "ra-auth-msal";
import { useEffect, useState } from "react";
import {
  AfrrEngagementResourcesEnum,
  EcpResourcesEnum,
  MaintenanceResourcesEnum,
  MecapaResourcesEnum,
  OptimizerResourcesEnum,
  PlanningResourcesEnum,
  RegelleistungResourcesEnum,
  RteCommunicationResourcesEnum,
  RteCommunicationV2ResourcesEnum,
  SiteResourcesEnum,
  TestsFingridEnum,
  TestsRteResourcesEnum,
  TopNiveauResourcesEnum,
  UserResourcesEnum,
} from "shared/enums/resourceMappings";
import CustomSolarLayout from "./CustomSolarLayout";
import EcpGateList from "entities/ecp/gates/EcpGateList";

const redirectOnCheckAuth = true;

const App = () => {
  const [isMSALInitialized, setMSALInitialized] = useState(false);
  const [authProvider, setAuthProvider] = useState<any | null>(null);
  const [dataProvider, setDataProvider] = useState<any | null>(null);

  useEffect(() => {
    MSALObj.initialize().then(() => {
      setMSALInitialized(true);

      const authProvider = msalAuthProvider({
        msalInstance: MSALObj,
        loginRequest,
        tokenRequest,
        getPermissionsFromAccount,
        redirectOnCheckAuth,
      });
      setAuthProvider(authProvider);

      const dataProvider = addRefreshAuthToDataProvider(
        combinedDataProvider,
        msalRefreshAuth({
          msalInstance: MSALObj,
          tokenRequest,
        }),
      );
      setDataProvider(dataProvider);
    });
  }, []);

  if (!isMSALInitialized || !authProvider || !dataProvider) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <Admin
        theme={ReTheme}
        dataProvider={dataProvider}
        authProvider={authProvider as any}
        i18nProvider={i18nProvider}
        requireAuth
        layout={CustomSolarLayout}
      >
        {(permissions) => {
          return (
            <>
              <Resource
                name={SiteResourcesEnum.Sites}
                options={{ label: "Site" }}
                list={SiteList}
                edit={SiteEdit}
                show={SiteShow}
                create={SiteCreate}
                recordRepresentation={(record) => {
                  return `${record?.id} : ${record?.name}`;
                }}
              />
              <Resource
                name={SiteResourcesEnum.Statuses}
                options={{ label: "Statuts des sites" }}
                list={StatusList}
                show={StatusShow}
                edit={StatusEdit}
                recordRepresentation="name"
              />
              <Resource
                name={SiteResourcesEnum.StatusesV2}
                options={{ label: "Statuts V2 des sites" }}
                list={StatusesSimpleList}
                // show={StatusShow}
                // edit={StatusEdit}
                recordRepresentation="name"
              />
              <Resource
                name={SiteResourcesEnum.Groups}
                list={GroupList}
                show={GroupShow}
                edit={GroupEdit}
                create={GroupCreate}
                recordRepresentation={(record) => {
                  return `${record?.id} : ${record?.name} - ${record?.code}`;
                }}
              />
              <Resource name={SiteResourcesEnum.Telemetry1S} recordRepresentation="timestamp" />
              <Resource name={SiteResourcesEnum.NLevels} recordRepresentation="timestamp" />
              <Resource
                name={SiteResourcesEnum.PerformanceChronicles}
                list={PerformanceList}
                options={{ label: "Config Validation" }}
              />
              <Resource
                name={SiteResourcesEnum.PerformanceChronicleGeneration}
                list={GenerationInitialForm}
                options={{ label: "Report Generation" }}
              />
              <Resource name={SiteResourcesEnum.PerformanceChronicleResults} />
              <Resource name={SiteResourcesEnum.SiteConfigurations} />
              <Resource
                name={RegelleistungResourcesEnum.RegelleistungBids}
                options={{ label: "Bids" }}
                list={BidsList}
              />
              <Resource
                name={PlanningResourcesEnum.Plannings}
                options={{ label: "Plannings" }}
                list={PlanningsList}
                show={PlanningShow}
                edit={PlanningEdit}
                create={PlanningCreate}
                recordRepresentation={(record) => {
                  return `site ${record?.site_id} : ${parseISO(record?.start_date)}`;
                }}
              />
              <Resource
                name={PlanningResourcesEnum.PlanningMasks}
                options={{ label: "Planning Masks" }}
                list={PlanningMaskList}
                create={PlanningMaskCreate}
              />
              <Resource
                name={MaintenanceResourcesEnum.Maintenance}
                options={{ label: "Maintenances" }}
                list={MaintenancesList}
                show={MaintenancesShow}
                edit={MaintenancesEdit}
                create={MaintenanceCreate}
                recordRepresentation="name"
              />
              <Resource
                name={OptimizerResourcesEnum.OptiCertificationsTests}
                list={CertificationsTestsList}
                show={CertificationsTestsShow}
                edit={CertificationsTestsEdit}
                create={CertificationsTestsCreate}
                options={{ label: "Certifications tests" }}
              />
              <Resource
                name={OptimizerResourcesEnum.OptiCertifications}
                list={CertificationsList}
                show={CertificationsShow}
                edit={CertificationsEdit}
                create={CertificationsCreate}
                recordRepresentation="certif_name"
                options={{ label: "Certifications" }}
              />
              <Resource
                name={OptimizerResourcesEnum.OptiGroupsCertifications}
                list={GroupsCertificationsList}
                create={GroupsCertificationsCreate}
                options={{ label: "Groups Certifications" }}
              />
              <Resource
                name={OptimizerResourcesEnum.OptiTasks}
                list={OptiTasksList}
                options={{ label: "Opti Tasks" }}
              />
              <Resource
                name={AfrrEngagementResourcesEnum.Point}
                list={AfrrEngagementPointList}
                options={{ label: "Points" }}
              />
              <Resource
                name={AfrrEngagementResourcesEnum.Engagement}
                list={AfrrEngagementVisualization}
                options={{ label: "Engagement" }}
              />
              <Resource
                name={AfrrEngagementResourcesEnum.BidTimeSeries}
                list={AfrrEngagementBidTimeSeriesList}
                show={AfrrEngagementBidTimeserieShow}
              />
              <Resource
                name={TopNiveauResourcesEnum.TopNiveauBids}
                list={TopNiveauBidsList}
                show={TopNiveauBidsShow}
                edit={TopNiveauBidsEdit}
                create={TopNiveauBidsCreate}
                options={{ label: "Top Niveau Bids" }}
              />
              <Resource
                name={TopNiveauResourcesEnum.TopNiveauGates}
                list={TopNiveauGatesList}
                show={TopNiveauGatesShow}
                edit={TopNiveauGatesEdit}
                create={TopNiveauGatesCreate}
                options={{ label: "Top Niveau Gates" }}
              />
              <Resource
                name={EcpResourcesEnum.EcpReservePlanGates}
                list={EcpGateList}
                options={{ label: "ECP Reserve Plan Gates" }}
              />
              <Resource
                name={EcpResourcesEnum.EcpReservePlanPoints}
                list={DeliveryDatePoints}
                options={{ label: "ECP Reserve Plan Points" }}
              />
              <Resource
                name={UserResourcesEnum.Users}
                options={{ label: "Utilisateurs" }}
                list={UsersList}
                show={UserShow}
                edit={UserEdit}
                create={permissions === "admin" ? UserCreate : undefined}
                recordRepresentation="name"
              />
              <Resource
                name={RteCommunicationResourcesEnum.RteCommunicationAfrrTestConfig}
                options={{ label: "N level loss" }}
                list={NLevelLossList}
                create={NlevelLossCreate}
                // recordRepresentation="name"
              />
              <Resource name={RteCommunicationResourcesEnum.RteCommunicationGroups} recordRepresentation="code" />
              <Resource
                name={RteCommunicationResourcesEnum.RteCommunicationFakePas}
                list={FakePasList}
                create={FakePasCreate}
                options={{ label: "Rte communication fake PAs" }}
              />

              <Resource
                name={RteCommunicationV2ResourcesEnum.RteCommunicationConfig}
                list={RteCommunicationConfigList}
                options={{ label: "Tranches TM" }}
              />
              <Resource
                name={RteCommunicationV2ResourcesEnum.RteCommunicationFakePa}
                list={RteCommunicationFakePaList}
                options={{ label: "Fake PA" }}
              />
              <Resource
                name={RteCommunicationV2ResourcesEnum.RteCommunicationTestConfig}
                list={RteCommunicationTestConfigList}
                options={{ label: "Test Configurations" }}
              />
              <Resource
                name={RteCommunicationV2ResourcesEnum.RteCommunicationMarketConfig}
                list={RteCommunicationMarketConfigList}
                options={{ label: "Market Configurations" }}
                recordRepresentation={(record) => {
                  return `EDR ${record?.edr_id} - ${record?.market}`;
                }}
              />

              <Resource
                name={TestsRteResourcesEnum.TestsAfrrRte}
                list={TestsAfrrRtePlanningsContainer}
                options={{ label: "Tests AFRR RTE" }}
              />
              <Resource
                name={TestsRteResourcesEnum.TestsFcrRte}
                list={TestsRtePlanningsContainer}
                options={{ label: "Tests FCR RTE" }}
              />
              <Resource
                name={TestsFingridEnum.TestFingridFcrN}
                list={TestFingridFcrN}
                options={{ label: "Tests Fingrid FCR-N" }}
              />
              <Resource
                name={MecapaResourcesEnum.EmaxHGates}
                list={EmaxHGateList}
                options={{ label: "Emax H Gates" }}
              />
              <Resource
                name={MecapaResourcesEnum.EmaxJGates}
                list={EmaxJGateList}
                options={{ label: "Emax J Gates" }}
              />
              {/* <Resource name={MecapaResourcesEnum.RtePowerGates} list={ListGuesser}
                options={{ label: "Rte Power Gates" }}
              /> */}
            </>
          );
        }}
      </Admin>
    </BrowserRouter>
  );
};

export default App;
