import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Datagrid, List, TextField, TextInput, useRecordContext } from "react-admin";
import CustomBooleanField from "shared/components/fields/CustomBooleanField";
import CustomDateField from "shared/components/fields/CustomDateField";
import CustomDateInput from "shared/components/inputs/CustomDateInput";

// Finland time zone and French locale options
const dateOptions: Intl.DateTimeFormatOptions = {
  timeZone: "Europe/Helsinki",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

const gateFilters = [
  <TextInput key="id_in" label="ID" source="id__in" />,
  <TextInput key="message_id" label="Message ID" source="message_id" />,
  <CustomDateInput key="delivery_date" label="Delivery Date" source="delivery_date" />,
  <CustomDateInput key="delivery_date_gte" label="Delivery Date GTE" source="delivery_date__gte" />,
  <CustomDateInput key="delivery_date_lte" label="Delivery Date LTE" source="delivery_date__lte" />,
];

const EcpGateList = () => {
  return (
    <List filters={gateFilters}>
      <Datagrid
        expandSingle
        bulkActionButtons={false}
        rowClick={"expand"}
        expand={<PointsDatagrid />}
        sx={{
          "& .RaDatagrid-expandedPanel": {
            backgroundColor: "gainsboro",
          },
        }}
      >
        <CustomDateField source="delivery_date" options={dateOptions} label={"Delivery date (Finland Time)"} />
        <CustomBooleanField source="accepted" />
        <TextField source="reason_text" />
        <CustomDateField source="created_at" showTime />
      </Datagrid>
    </List>
  );
};

export default EcpGateList;

const PointsDatagrid = () => {
  const record = useRecordContext();
  if (!record) return null;
  console.log("record:", record);
  const formatToFinlandTime = (date: string) => {
    return new Date(date).toLocaleString("fr-FR", dateOptions);
  };
  return (
    <TableContainer sx={{ bgcolor: "aliceBlue" }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Start Date (Finland time)</TableCell>
            <TableCell>End Date (Finland time)</TableCell>
            <TableCell>Market</TableCell>
            <TableCell>Direction</TableCell>
            <TableCell>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.points.map(({ id, start_date, end_date, business_type, direction, quantity_mw }: any) => (
            <TableRow key={id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell>{formatToFinlandTime(start_date)}</TableCell>
              <TableCell>{formatToFinlandTime(end_date)}</TableCell>
              <TableCell>{business_type}</TableCell>
              <TableCell>{direction}</TableCell>
              <TableCell>{quantity_mw}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
