import { Datagrid, DeleteButton, FunctionField, List, RaRecord, ReferenceField, TextField } from "react-admin";
import CustomDateField from "shared/components/fields/CustomDateField";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";

export const NLevelLossList = () => (
  <List>
    <Datagrid>
      <TextField source="id" />
      <CustomDateField source="no_n_level_start" locales={"fr-FR"} showTime />
      <CustomDateField source="no_n_level_end" locales={"fr-FR"} showTime />
      <ReferenceField source="group_id" reference={SiteResourcesEnum.Groups} link={false}>
        <FunctionField
          render={(record: RaRecord) => `${record.id} ${record.name} ${record.code} | mode: ${record.mode}`}
        />
      </ReferenceField>
      <DeleteButton />
    </Datagrid>
  </List>
);
