import { enumToChoices, multipleEnumsToChoices } from "shared/utils/reactAdminHelpers";
import { EcpBidBusinessTypeEnum, EcpBidDirectionEnum, EcpBidStatusEnum } from "./ecpEnums";
import {
  AfrrPointStatusEnum,
  allDsoEnum,
  ChronicleFilenamesEnum,
  DirectionEnum,
  FinlandGroupTypeEnum,
  FinlandPlanningMaskModeEnum,
  FinlandPlanningModeEnum,
  FranceGroupTypeEnum,
  FrancePlanningMaskModeEnum,
  FrancePlanningModeEnum,
  GroupModeEnum,
  GroupTypeEnum,
  MaintenanceTypeEnum,
  PacketTypeEnum,
  PlanningModeEnum,
  RteCommunicationMarketStatusEnum,
  RteCommunicationMarketTypeEnum,
  RteCommunicationTestConfigTypeEnum,
  RteComputationVersionEnum,
  SiteContryEnum,
  SiteFinlandDsoEnum,
  SiteFinlandSupplierEnum,
  SiteFranceSupplierEnum,
  SiteFrenchDsoEnum,
  SiteModeEnum,
  SiteOperatorEnum,
  TITypeEnum,
  TopNiveauGateStatusEnum,
} from "./globalEnums";
import {
  OptimizerCertificationStatusEnum,
  OptimizerCertificationsTestsTypeEnum,
  OptimizerCountryEnum,
  OptimizerDirectionEnum,
  OptimizerGroupTypeEnum,
  OptimizerMarketEnum,
} from "./opti_enums";

export type RaChoiceType = {
  id: string;
  name: string;
};

export const siteFrenchDsoChoices = enumToChoices(SiteFrenchDsoEnum);
export const siteFinlandDsoChoices = enumToChoices(SiteFinlandDsoEnum);
export const allDsoChoices = enumToChoices(allDsoEnum);

export const siteCountryChoices = enumToChoices(SiteContryEnum);

export const siteOperatorChoices = enumToChoices(SiteOperatorEnum);

export const siteFranceSupplierChoices = enumToChoices(SiteFranceSupplierEnum);
export const siteFinlandSupplierChoices = enumToChoices(SiteFinlandSupplierEnum);

export const rteComputationVersionChoices = enumToChoices(RteComputationVersionEnum);

export const chronicleFilenamesChoices = enumToChoices(ChronicleFilenamesEnum);

export const planningModeChoices = enumToChoices(PlanningModeEnum);
export const francePlanningModeChoices = enumToChoices(FrancePlanningModeEnum);
export const finlandPlanningModeChoices = enumToChoices(FinlandPlanningModeEnum);

export const planningMaskModeChoices = multipleEnumsToChoices([
  FrancePlanningMaskModeEnum,
  FinlandPlanningMaskModeEnum,
]);
export const francePlanningMaskModeChoices = enumToChoices(FrancePlanningMaskModeEnum);
export const finlandPlanningMaskModeChoices = enumToChoices(FinlandPlanningMaskModeEnum);

export const groupModeChoices = enumToChoices(GroupModeEnum);
export const groupTypeChoices = enumToChoices(GroupTypeEnum);
export const franceGroupTypeChoices = enumToChoices(FranceGroupTypeEnum);
export const finlandGroupTypeChoices = enumToChoices(FinlandGroupTypeEnum);

export const maintenanceTypeChoices = enumToChoices(MaintenanceTypeEnum);

export const siteModeChoices = enumToChoices(SiteModeEnum);

export const directionChoices = enumToChoices(DirectionEnum);
export const afrrPointStatusChoices = enumToChoices(AfrrPointStatusEnum);

export const topNiveauGateStatusChoices = enumToChoices(TopNiveauGateStatusEnum);

export const tiTypeChoices = enumToChoices(TITypeEnum);
export const packetTypeChoices = enumToChoices(PacketTypeEnum);

export const rteCommunicationMarketTypeChoices = enumToChoices(RteCommunicationMarketTypeEnum);
export const rteCommunicationMarketStatusChoices = enumToChoices(RteCommunicationMarketStatusEnum);
export const rteCommunicationTestConfigTypeChoices = enumToChoices(RteCommunicationTestConfigTypeEnum);

export const optimizerCountryChoices = enumToChoices(OptimizerCountryEnum);
export const optimizerDirectionChoices = enumToChoices(OptimizerDirectionEnum);
export const optimizerCertificationsTestsTypeChoices = enumToChoices(OptimizerCertificationsTestsTypeEnum);
export const optimizerMarketChoices = enumToChoices(OptimizerMarketEnum);
export const optimizerCertificationStatusChoices = enumToChoices(OptimizerCertificationStatusEnum);
export const optimizerGroupTypeChoices = enumToChoices(OptimizerGroupTypeEnum);

export const ecpBidBusinessTypeChoices = enumToChoices(EcpBidBusinessTypeEnum);

export const ecpBidStatusChoices = enumToChoices(EcpBidStatusEnum);

export const ecpBidDirectionChoices = enumToChoices(EcpBidDirectionEnum);
