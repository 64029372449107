import { Alert, FormControlLabel, Grid, MenuItem, Paper, Switch, Typography } from "@mui/material";
import { FormikField } from "shared/components/inputs/FormikInput";
import { parseAndFormatDate, validateFormik5MinPlanningTimeInArray } from "shared/utils/validations";
import { add } from "date-fns";
import { PlanningModeEnum } from "shared/enums/globalEnums";
import { FieldArray, Form as FormikForm, useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import { Button, Toolbar } from "react-admin";
import {
  formatPlanningsDates,
  formatUStoFrDate,
  getPlanningBackgoundColor,
  updateFollowingPlannings,
} from "./fcrHelpers";

export default function TestsRtePlanningsForm() {
  const { values, setStatus, status }: any = useFormikContext();
  useEffect(() => {
    if (values && "plannings" in values && values.plannings && values.plannings.length > 0) {
      setStatus({
        hasOverlap: values.plannings
          .filter((planning: any) => !planning.disabled)
          .some((planning: any) => !planning.hasDatesOnSameDay),
      });
    }
  }, [values, setStatus]);

  return (
    <FormikForm>
      <FieldArray name="plannings" render={() => <RtePLanningsSubForm />} />
      <Toolbar
        sx={{
          mt: 2,
        }}
      >
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={status && "hasOverlap" in status ? status.hasOverlap : false}
        >
          <Typography>Valider</Typography>
        </Button>
      </Toolbar>
    </FormikForm>
  );
}

const RtePLanningsSubForm = () => {
  const { values, errors, handleChange, setValues }: any = useFormikContext();
  const modeOptions = useMemo(
    () =>
      Object.values(PlanningModeEnum).map((mode, index) => (
        <MenuItem key={index} value={mode}>
          {mode}
        </MenuItem>
      )),

    [],
  );

  const handleDateChange = useCallback(
    (value: string, targetedIndex: number) => {
      const newplanningValues = values.plannings.reduce((acc: any, currentPlanning: any, planningIndex: number) => {
        if (planningIndex < targetedIndex || currentPlanning.disabled)
          return [
            ...acc,
            {
              ...currentPlanning,
              start_date: new Date(currentPlanning.start_date),
              end_date: new Date(currentPlanning.end_date),
            },
          ];
        if (planningIndex === targetedIndex) {
          return [
            ...acc,
            {
              ...currentPlanning,
              start_date: new Date(value),
              end_date: add(new Date(value), currentPlanning.duration),
            },
          ];
        }
        return updateFollowingPlannings(acc, currentPlanning);
      }, []);
      setValues({
        ...values,
        plannings: formatPlanningsDates(newplanningValues),
      });
    },
    [values, setValues],
  );

  const toggleDisablePlanning = useCallback(
    (targetedIndex: number) => {
      const newplanningValues = values.plannings.reduce((acc: any, currentPlanning: any, planningIndex: number) => {
        if (planningIndex <= targetedIndex)
          return [
            ...acc,
            {
              ...currentPlanning,
              start_date: new Date(currentPlanning.start_date),
              end_date: new Date(currentPlanning.end_date),
              disabled: planningIndex === targetedIndex ? !currentPlanning.disabled : currentPlanning.disabled,
            },
          ];
        return updateFollowingPlannings(acc, currentPlanning);
      }, []);
      setValues({
        ...values,
        plannings: formatPlanningsDates(newplanningValues),
      });
    },
    [values, setValues],
  );

  return (
    <Grid container rowGap={2} mt={2}>
      {values.plannings &&
        values.plannings.length > 0 &&
        values.plannings.map((planning: any, index: number) => {
          const baseName = `plannings[${index}]`;
          const currentPlanning = values.plannings[index];
          const baseError = errors && errors.plannings && errors.plannings[index];
          return (
            <Grid
              item
              container
              key={index}
              spacing={2}
              borderRadius={2}
              component={Paper}
              elevation={4}
              //   mb={2}
              mt={1}
              pb={2}
              pr={2}
              ml={0}
              bgcolor={getPlanningBackgoundColor(planning)}
            >
              <Grid item container xs={12} columnSpacing={4}>
                <Grid item>
                  <Typography variant="h5">{planning.name}</Typography>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        name={`${baseName}.disabled`}
                        onChange={() => {
                          toggleDisablePlanning(index);
                        }}
                        checked={currentPlanning.disabled}
                      />
                    }
                    label="Disable"
                  />
                </Grid>
                {!currentPlanning.hasDatesOnSameDay && (
                  <Grid item>
                    <Alert severity="error">The dates are overlapping two days</Alert>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={3}>
                <FormikField
                  disabled={currentPlanning.disabled}
                  type="datetime-local"
                  name={`${baseName}.start_date`}
                  label={"Start Date"}
                  validate={(datetime: string) => validateFormik5MinPlanningTimeInArray(datetime, index, values)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDateChange(e.target.value, index)}
                  errorMessage={baseError && errors.plannings[index].start_date}
                  inputProps={{ min: parseAndFormatDate(values.plannings[index - 1]?.end_date) }}
                />
              </Grid>
              <Grid item container justifyContent={"center"} alignItems={"center"} flexDirection={"column"} xs={3}>
                <Typography variant="caption">End date</Typography>
                <Typography>{formatUStoFrDate(currentPlanning.end_date)}</Typography>
              </Grid>
              <Grid item xs={3}>
                <FormikField disabled={currentPlanning.disabled} select name={`${baseName}.mode`} label={"Mode"}>
                  {modeOptions}
                </FormikField>
              </Grid>

              <Grid item xs={3}>
                <FormikField
                  disabled={currentPlanning.disabled}
                  type="number"
                  name={`${baseName}.fcr_engagement`}
                  label={"FCR engagement"}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikField
                  disabled={currentPlanning.disabled}
                  type="number"
                  name={`${baseName}.initial_soc_restoration_power`}
                  label={"Initial soc restoration power"}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikField
                  disabled={currentPlanning.disabled}
                  type="number"
                  name={`${baseName}.soc_restoration_power`}
                  label={"Soc restoration power"}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikField
                  disabled={currentPlanning.disabled}
                  name={`${baseName}.chronicle_filename`}
                  label={"Chronicle filename"}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikField
                  disabled={currentPlanning.disabled}
                  type="number"
                  name={`${baseName}.reactivity_delay`}
                  label={"Reactivity delay"}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikField
                  disabled={currentPlanning.disabled}
                  type="number"
                  name={`${baseName}.target_soc`}
                  label={"Target soc"}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikField
                  disabled={currentPlanning.disabled}
                  type="number"
                  name={`${baseName}.target_power_mw`}
                  label={"Target power Mw"}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={currentPlanning.disabled}
                      name={`${baseName}.overidable`}
                      onChange={handleChange}
                      checked={currentPlanning.overidable}
                    />
                  }
                  label="Overridable"
                />
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};
