import { Box, Typography } from "@mui/material";
import { msalAccountName } from "app/providers/auth/authConfig";
import { parseISO } from "date-fns";
import {
  Edit,
  FormDataConsumer,
  Labeled,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useUpdate,
} from "react-admin";
import CustomDateField from "shared/components/fields/CustomDateField";
import FormContainer from "shared/components/forms/FormContainer";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { maintenanceTypeChoices } from "shared/enums/reactAdminChoices";
import { MaintenanceResourcesEnum } from "shared/enums/resourceMappings";
import { parseErrorMessage } from "shared/utils/helpers";
import { validateMaintenanceEndDate, validatePlanningTime } from "shared/utils/validations";

export function MaintenancesEdit() {
  const [update] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (values: any) => {
    const { id, ...data } = values;
    const updatedData = {
      ...data,
      operator: msalAccountName,
    };
    update(
      MaintenanceResourcesEnum.Maintenance,
      { data: updatedData, id },
      {
        onError: (error: any) => {
          notify(parseErrorMessage(error), {
            type: "error",
          });
        },
        onSuccess: () => {
          notify("Maintenance updated", {
            type: "success",
          });
          redirect(MaintenanceResourcesEnum.Maintenance);
        },
      },
    );
  };
  return (
    <Edit>
      <SimpleForm mode="onBlur" onSubmit={handleSubmit}>
        <FormContainer>
          <SelectInput source="type" choices={maintenanceTypeChoices} />
          <FormDataConsumer>
            {({ formData }) => {
              const startBeforeToday = formData.start && parseISO(formData.start) <= new Date();
              return startBeforeToday ? (
                <Box display={"flex"} flexDirection={"column"}>
                  <Labeled source="start">
                    <CustomDateField source="start" showTime />
                  </Labeled>
                  <Typography variant="caption" color={"primary"}>
                    Passed start dates cannot be changed
                  </Typography>
                </Box>
              ) : (
                <CustomDateTimeInput source="start" validate={[validatePlanningTime]} minDate={new Date()} />
              );
            }}
          </FormDataConsumer>
          <CustomDateTimeInput
            source="end"
            validate={[validatePlanningTime, validateMaintenanceEndDate]}
            minDate={new Date()}
          />

          <ReferenceInput source="site_id" reference="sites" perPage={100} />
          {/* <TextInput source="operator" /> */}
          <TextInput source="comment" multiline />
        </FormContainer>
      </SimpleForm>
    </Edit>
  );
}
