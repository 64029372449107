import { CreateDialog } from "@react-admin/ra-form-layout";
import {
  AutocompleteArrayInput,
  Datagrid,
  DeleteButton,
  List,
  ListActions,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  TextField,
} from "react-admin";
import CustomDateField from "shared/components/fields/CustomDateField";
import RteCommunicationFakePaCreate from "./RteCommunicationFakePaCreate";
import { RteCommunicationV2ResourcesEnum, SiteResourcesEnum } from "shared/enums/resourceMappings";
import { useMemo } from "react";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { FranceGroupTypeEnum } from "shared/enums/globalEnums";

const RteCommunicationFakePaList = () => {
  const filters = useMemo(
    () => [
      <ReferenceArrayInput
        label="Fake PAs"
        source="id__in"
        reference={RteCommunicationV2ResourcesEnum.RteCommunicationFakePa}
        key="id__in"
      >
        <AutocompleteArrayInput
          label="Fake PAs"
          sx={{ minWidth: 500 }}
          optionText={(choice) => `EDP id=${choice.edp_id} : ${choice.start_date} -> ${choice.end_date}`}
        />
      </ReferenceArrayInput>,

      <ReferenceArrayInput
        label="EDPs"
        source="edp_id__in"
        reference={SiteResourcesEnum.Groups}
        key="edp_id__in"
        filter={{ type: FranceGroupTypeEnum.rte_edp }}
      >
        <AutocompleteArrayInput label="EDPs" />
      </ReferenceArrayInput>,

      <CustomDateTimeInput label="Start date >=" source="start_date__gte" key="start_date__gte" />,
      <CustomDateTimeInput label="Start date <=" source="start_date__lte" key="start_date__lte" />,
      <CustomDateTimeInput label="Start date >" source="start_date__gt" key="start_date__gt" />,
      <CustomDateTimeInput label="Start date <" source="start_date__lt" key="start_date__lt" />,

      <CustomDateTimeInput label="End date >=" source="end_date__gte" key="end_date__gte" />,
      <CustomDateTimeInput label="End date <=" source="end_date__lte" key="end_date__lte" />,
      <CustomDateTimeInput label="End date >" source="end_date__gt" key="end_date__gt" />,
      <CustomDateTimeInput label="End date <" source="end_date__lt" key="end_date__lt" />,

      <NumberInput label="R1 H >=" source="r1_h__gte" key="r1_h__gte" />,
      <NumberInput label="R1 H <=" source="r1_h__lte" key="r1_h__lte" />,
      <NumberInput label="R1 H >" source="r1_h__gt" key="r1_h__gt" />,
      <NumberInput label="R1 H <" source="r1_h__lt" key="r1_h__lt" />,

      <NumberInput label="R1 B >=" source="r1_b__gte" key="r1_b__gte" />,
      <NumberInput label="R1 B <=" source="r1_b__lte" key="r1_b__lte" />,
      <NumberInput label="R1 B >" source="r1_b__gt" key="r1_b__gt" />,
      <NumberInput label="R1 B <" source="r1_b__lt" key="r1_b__lt" />,

      <NumberInput label="R2 H >=" source="r2_h__gte" key="r2_h__gte" />,
      <NumberInput label="R2 H <=" source="r2_h__lte" key="r2_h__lte" />,
      <NumberInput label="R2 H >" source="r2_h__gt" key="r2_h__gt" />,
      <NumberInput label="R2 H <" source="r2_h__lt" key="r2_h__lt" />,

      <NumberInput label="R2 B >=" source="r2_b__gte" key="r2_b__gte" />,
      <NumberInput label="R2 B <=" source="r2_b__lte" key="r2_b__lte" />,
      <NumberInput label="R2 B >" source="r2_b__gt" key="r2_b__gt" />,
      <NumberInput label="R2 B <" source="r2_b__lt" key="r2_b__lt" />,

      <NumberInput label="P0 >=" source="p0__gte" key="p0__gte" />,
      <NumberInput label="P0 <=" source="p0__lte" key="p0__lte" />,
      <NumberInput label="P0 >" source="p0__gt" key="p0__gt" />,
      <NumberInput label="P0 <" source="p0__lt" key="p0__lt" />,
    ],
    [],
  );

  return (
    <>
      <List actions={<ListActions hasCreate />} filters={filters}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" />
          <ReferenceField source="edp_id" reference={SiteResourcesEnum.Groups} />
          <CustomDateField showTime source="start_date" />
          <CustomDateField showTime source="end_date" />
          <TextField source="r1_h" />
          <TextField source="r1_b" />
          <TextField source="r2_h" />
          <TextField source="r2_b" />
          <TextField source="p0" />
          <CustomDateField showTime source="created_at" />
          <CustomDateField showTime source="updated_at" />
          <DeleteButton />
        </Datagrid>
      </List>
      <CreateDialog fullWidth maxWidth="sm">
        <RteCommunicationFakePaCreate />
      </CreateDialog>
    </>
  );
};

export default RteCommunicationFakePaList;
