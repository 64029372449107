import { Identifier, useListContext, useResourceContext } from "react-admin";

import jsonExport from "jsonexport/dist";
import { useCallback, useMemo } from "react";
import { downloadCSV, useDataProvider, useNotify } from "react-admin";
import { fetchAllRecords } from "../helpers";

type useExportButtonProps = {
  headers?: string[];
  includeDeleted?: boolean;
  filename?: string;
  filter?: { [key: Identifier]: any };
};

export const useExportButton = ({ headers, filename, includeDeleted = false, filter }: useExportButtonProps) => {
  const resource = useResourceContext() || "";
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const context = useListContext();
  const filterValues = context.filterValues;
  const sort = useMemo(() => context?.sort || { field: "id", order: "ASC" }, [context?.sort]);

  const handleClick = useCallback(async () => {
    try {
      const config = {
        filter: filter || filterValues,
        pagination: { page: 1, perPage: 100 },
        sort,
      };
      const data = await fetchAllRecords(dataProvider, resource, config, includeDeleted);

      jsonExport(data, { headers }, (err, csv) => {
        const now = new Date().toISOString();
        downloadCSV(csv, `${now}_${filename}` || `${now}_${resource}`);
      });
    } catch (error) {
      notify("Error fetching data for export", { type: "warning" });
    }
  }, [dataProvider, resource, notify, filterValues, sort, includeDeleted, headers, filename, filter]);

  return {
    handleClick,
  };
};
