import { useMemo } from "react";
import { Datagrid, List, SelectInput, TextField } from "react-admin";
import { GenericColorChip } from "shared/components/chips/GenericColorChip";
import {
  OptimizerCountryColorMapping,
  OptimizerMarketColorMapping,
} from "shared/enums/colorMappings/optiColorMappings";
import { optimizerCountryChoices, optimizerMarketChoices } from "shared/enums/reactAdminChoices";

export default function CertificationsList() {
  const CertificationsFilters = useMemo(
    () => [
      <SelectInput key="market" label="Market" source="market" choices={optimizerMarketChoices} />,
      <SelectInput key="country" label="Country" source="country" choices={optimizerCountryChoices} />,
    ],
    [],
  );

  return (
    <List filters={CertificationsFilters}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="id" />
        <TextField source="certif_name" />
        <GenericColorChip source="market" colorMapping={OptimizerMarketColorMapping} />
        <GenericColorChip source="country" colorMapping={OptimizerCountryColorMapping} />
      </Datagrid>
    </List>
  );
}
