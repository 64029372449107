import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import {
  Datagrid,
  Form,
  ListContextProvider,
  NumberField,
  SaveButton,
  SortPayload,
  useGetList,
  useList,
} from "react-admin";
import { GenericColorChip } from "shared/components/chips/GenericColorChip";
import CustomDateField from "shared/components/fields/CustomDateField";
import CustomDateInput from "shared/components/inputs/CustomDateInput";
import {
  ecpBidBusinessTypeColorMapping,
  EcpBidDirectionColorMapping,
} from "shared/enums/colorMappings/ecpColorMappings";
import { EcpResourcesEnum } from "shared/enums/resourceMappings";

const DeliveryDatePoints = () => {
  const [deliveryDate, setDeliveryDate] = useState<Date>();
  const handleSumit = (values: any) => {
    setDeliveryDate(values.deliveryDate);
  };
  return (
    <>
      <Form onSubmit={handleSumit}>
        <Stack spacing={2} sx={{ maxWidth: 300, m: 2 }}>
          <CustomDateInput source="deliveryDate" />
          <SaveButton
            label="Search delivery date"
            sx={{
              marginTop: "0px !important",
            }}
          />
        </Stack>
      </Form>
      {deliveryDate && <PointList deliveryDate={deliveryDate} />}
    </>
  );
};

export default DeliveryDatePoints;

type PointListProps = {
  deliveryDate: Date;
};

const PointList = ({ deliveryDate }: PointListProps) => {
  const urlParams = `/effective/${format(deliveryDate, "yyyy-MM-dd")}`;
  const config = {
    sort: { field: "id", order: "ASC" } as SortPayload,
    pagination: { page: 1, perPage: 100 },
    meta: {
      urlParams,
    },
  };
  const { data, isPending } = useGetList(EcpResourcesEnum.EcpReservePlanPoints, config);
  const listContext = useList({ data, isPending });

  return (
    <ListContextProvider value={listContext}>
      <Card sx={{ m: 2 }}>
        <CardHeader title={"Delivery Date : " + format(deliveryDate, "dd/MM/yyyy")} />
        <CardContent>
          <Datagrid bulkActionButtons={false}>
            <CustomDateField showTime source="start_date" />
            <CustomDateField showTime source="end_date" />
            <GenericColorChip source="business_type" colorMapping={ecpBidBusinessTypeColorMapping} />
            <GenericColorChip source="direction" colorMapping={EcpBidDirectionColorMapping} />
            <NumberField source="quantity_mw" />
          </Datagrid>
        </CardContent>
      </Card>
    </ListContextProvider>
  );
};
