import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton } from "@mui/material";
import axios, { AxiosError } from "axios";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useCallback, useMemo } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  ButtonProps,
  Create,
  FormDataConsumer,
  FormDataConsumerRenderParams,
  Identifier,
  Loading,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  required,
  useGetOne,
  useNotify,
  useSimpleFormIterator,
  useSimpleFormIteratorItem,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { Error } from "shared/components/statuses/Error";
import { PlanningModeEnum, SiteContryEnum } from "shared/enums/globalEnums";
import {
  chronicleFilenamesChoices,
  finlandPlanningModeChoices,
  francePlanningModeChoices,
} from "shared/enums/reactAdminChoices";
import { PlanningResourceMapping, PlanningResourcesEnum, SiteResourcesEnum } from "shared/enums/resourceMappings";
import useStatusAware from "shared/hooks/useStatusAware";
import { getAxiosCustomErrorMessage } from "shared/utils/errorHandlers";
import { validatePlanning } from "./validateCreatePlanning";

const AddResourceButton = (props: ButtonProps) => {
  const { add } = useSimpleFormIterator();

  return (
    <Button onClick={() => add()} {...props}>
      {props.label}
    </Button>
  );
};
type RemoveResourceButtonProps = Omit<ButtonProps, "onClick"> & { isIconButton?: boolean };

const RemoveResourceButton = ({ isIconButton = false, ...props }: RemoveResourceButtonProps) => {
  const { remove } = useSimpleFormIteratorItem();

  const { startIcon, ...commonProps } = props;

  return isIconButton ? (
    <IconButton onClick={() => remove()} {...commonProps}>
      {startIcon}
    </IconButton>
  ) : (
    <Button onClick={() => remove()} color="warning" {...props}>
      {props.label}
    </Button>
  );
};

interface PlanningCreateProps {
  defaultValues: object | null;
}

export default function PlanningCreate({ defaultValues = null }: PlanningCreateProps) {
  const navigate = useNavigate();
  const notify = useNotify();
  const { loading, setLoading, error, setError } = useStatusAware(false);

  const postPlannings = useCallback(
    async (data: any) => {
      const url = `${process.env.REACT_APP_PLANNINGS_API_URL}/${
        PlanningResourceMapping[PlanningResourcesEnum.Plannings]
      }`;
      setLoading(true);
      try {
        await axios({
          method: "post",
          url,
          data,
        });
        notify(`Plannings posted successfully`, { type: "success" });
        setLoading(false);
        navigate(`/${PlanningResourcesEnum.Plannings}`);
      } catch (error) {
        setLoading(false);
        const message = getAxiosCustomErrorMessage(error as AxiosError);
        notify(`There was an error posting plannings: ${message}`, { type: "error" });
        setError(message);
      }
    },
    [notify, navigate, setLoading, setError],
  );

  const handleSubmit = useCallback(
    (values: any) => {
      const timeZone = "Europe/Paris"; // Set this to the desired timezone

      const data = values.sites.reduce((acc: any, currentSite: any) => {
        const { site_id, plannings } = currentSite;

        return {
          ...acc,
          [site_id]: plannings.map((planning: any) => {
            // Convert start_date and end_date to timezone-aware format
            const formatDate = (date: string | null) =>
              date ? format(utcToZonedTime(new Date(date), timeZone), "yyyy-MM-dd'T'HH:mm:ssXXX") : null;

            const startDate = formatDate(planning?.start_date);
            const endDate = formatDate(planning?.end_date);

            return {
              ...planning,
              site_id: site_id,
              start_date: startDate,
              end_date: endDate,
              fcr_engagement: planning?.fcr_engagement !== "" ? planning?.fcr_engagement : null,
              initial_soc_restoration_power:
                planning?.initial_soc_restoration_power !== "" ? planning?.initial_soc_restoration_power : null,
              soc_restoration_power: planning?.soc_restoration_power !== "" ? planning?.soc_restoration_power : null,
              reactivity_delay: planning?.reactivity_delay !== "" ? planning?.reactivity_delay : null,
              target_power_mw: planning?.target_power_mw !== "" ? planning?.target_power_mw : null,
              target_soc: planning?.target_soc !== "" ? planning?.target_soc : null,
            };
          }),
        };
      }, {});

      postPlannings(data);
    },
    [postPlannings],
  );

  if (loading) return <Loading />;

  return (
    <Create>
      <SimpleForm
        onSubmit={handleSubmit}
        // onSubmit={handleSubmit}
        defaultValues={defaultValues}
        mode="onBlur"
        // sanitizeEmptyValues
        validate={validatePlanning}
      >
        {error && <Error error={error} />}
        <ArrayInput source="sites">
          <SimpleFormIterator
            inline
            fullWidth
            disableReordering
            disableClear
            removeButton={<RemoveResourceButton color={"error"} startIcon={<DeleteIcon />} label="Supprimer le site" />}
            addButton={
              <AddResourceButton variant="contained" startIcon={<AddCircleOutlineIcon />} label="Ajouter un site" />
            }
            sx={{
              "& .RaSimpleFormIterator-form": {
                flexDirection: "column",
              },
            }}
          >
            <ReferenceInput source="site_id" reference="sites" perPage={100}>
              <AutocompleteInput validate={required()} />
            </ReferenceInput>

            <FormDataConsumer>
              {({ scopedFormData }: FormDataConsumerRenderParams) => {
                const siteId = scopedFormData?.site_id;
                return (
                  <ArrayInput source="plannings">
                    <SimpleFormIterator
                      inline
                      disableReordering
                      disableClear
                      removeButton={
                        <RemoveResourceButton
                          color={"error"}
                          startIcon={<DeleteIcon />}
                          label={"Supprimer le planning"}
                        />
                      }
                      addButton={<AddResourceButton startIcon={<AddCircleOutlineIcon />} label="Ajouter un Planning" />}
                      sx={{
                        p: 2,
                        mb: 2,
                        boxShadow:
                          "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);",
                      }}
                    >
                      <CustomDateTimeInput source="start_date" validate={[required()]} />
                      <CustomDateTimeInput source="end_date" validate={[required()]} />
                      <FormDataConsumer>
                        {({ scopedFormData }: FormDataConsumerRenderParams) => {
                          const mode = scopedFormData?.mode;
                          return (
                            <>
                              {siteId ? (
                                <CountryDependingModeInput siteId={siteId} source={"mode"} mode={mode} />
                              ) : (
                                <SelectInput source={"mode"} choices={[]} disabled />
                              )}
                              {/* <ModeDependentInputs mode={mode} /> */}
                            </>
                          );
                        }}
                      </FormDataConsumer>
                      <BooleanInput source="overidable" defaultValue={false} label="Overridable" />
                    </SimpleFormIterator>
                  </ArrayInput>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
}

const CountryDependingModeInput = ({
  siteId,
  source,
  mode,
}: {
  siteId: Identifier;
  source: string;
  mode: PlanningModeEnum;
}) => {
  const { data, isLoading } = useGetOne(SiteResourcesEnum.Sites, { id: siteId });

  const country = data?.country;

  const getModeDependingOnCountry = useCallback(() => {
    switch (country) {
      case SiteContryEnum.France:
        return francePlanningModeChoices;
      case SiteContryEnum.Finland:
        return finlandPlanningModeChoices;
      default:
        return [];
    }
  }, [country]);

  if (isLoading) return <Loading />;
  return (
    <>
      <SelectInput source={source} choices={getModeDependingOnCountry()} validate={required()} />
      <ModeDependentInputs mode={mode} country={country} />
    </>
  );
};

enum PlanningFieldsEnum {
  fcr_engagement,
  target_soc,
  initial_soc_restoration_power,
  chronicle_filename,
  target_power_mw,
  fcrn_engagement,
  afrr_engagement,
  reactivity_delay,
  soc_restoration_power,
}

const ModeDependentInputs = ({ mode, country }: { mode: PlanningModeEnum; country: SiteContryEnum }) => {
  // Add more fields here if more markets or fields are added to the plannings
  const componentMapping: Record<PlanningFieldsEnum, JSX.Element> = useMemo(
    () => ({
      [PlanningFieldsEnum.fcr_engagement]: <NumberInput source={"fcr_engagement"} validate={[required()]} key={0} />,
      [PlanningFieldsEnum.target_soc]: <NumberInput source={"target_soc"} min={0} max={1} key={1} />,
      [PlanningFieldsEnum.initial_soc_restoration_power]: (
        <NumberInput source={"initial_soc_restoration_power"} key={2} />
      ),
      [PlanningFieldsEnum.chronicle_filename]: (
        <SelectInput source={"chronicle_filename"} choices={chronicleFilenamesChoices} key={3} />
      ),
      [PlanningFieldsEnum.target_power_mw]: <NumberInput source={"target_power_mw"} min={0} max={1000} key={4} />,
      [PlanningFieldsEnum.fcrn_engagement]: <NumberInput source={"fcrn_engagement"} validate={[required()]} key={5} />,
      [PlanningFieldsEnum.reactivity_delay]: (
        <NumberInput
          source={"reactivity_delay"}
          min={0}
          defaultValue={country === SiteContryEnum.France ? 1500 : 0}
          key={6}
        />
      ),
      [PlanningFieldsEnum.afrr_engagement]: <NumberInput source={"afrr_engagement"} validate={[required()]} key={7} />,
      [PlanningFieldsEnum.soc_restoration_power]: <NumberInput source={"soc_restoration_power"} key={8} />,
    }),
    [country],
  );

  // add, remove, switch inputs depending on the mode
  const modeComponentKeyMapping: Record<PlanningModeEnum, PlanningFieldsEnum[]> = useMemo(
    () => ({
      [PlanningModeEnum.fcr_rte_v2]: [
        PlanningFieldsEnum.fcr_engagement,
        PlanningFieldsEnum.soc_restoration_power,
        PlanningFieldsEnum.reactivity_delay,
      ],
      [PlanningModeEnum.fcr_rte_v2_no_reserve]: [
        PlanningFieldsEnum.fcr_engagement,
        PlanningFieldsEnum.soc_restoration_power,
      ],
      [PlanningModeEnum.power_chronicle]: [PlanningFieldsEnum.chronicle_filename, PlanningFieldsEnum.reactivity_delay],
      [PlanningModeEnum.power]: [PlanningFieldsEnum.target_power_mw],
      [PlanningModeEnum.soc_management]: [PlanningFieldsEnum.target_soc],
      [PlanningModeEnum.afrr_rte]: [PlanningFieldsEnum.afrr_engagement, PlanningFieldsEnum.soc_restoration_power],
      [PlanningModeEnum.afrr_fcr_rte]: [
        PlanningFieldsEnum.afrr_engagement,
        PlanningFieldsEnum.fcr_engagement,
        PlanningFieldsEnum.soc_restoration_power,
      ],
      [PlanningModeEnum.stop]: [],
      [PlanningModeEnum.fingrid_fcrn]: [
        PlanningFieldsEnum.fcrn_engagement,
        PlanningFieldsEnum.soc_restoration_power,
        PlanningFieldsEnum.reactivity_delay,
      ],
    }),
    [],
  );

  const componentKeys: PlanningFieldsEnum[] = modeComponentKeyMapping[mode] || [];

  return <>{componentKeys.map((key) => componentMapping[key])}</>;
};
