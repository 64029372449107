import { Card, CardHeader, Stack } from "@mui/material";
import { ResourceContext } from "react-admin";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import { StatusesSimpleList } from "./StatusesSimpleList";

const StatusList = () => {
  return (
    <Stack direction={"column"} spacing={2}>
      <Card sx={{ p: 2, m: 2 }}>
        <CardHeader subheader="Statuses V2 LATEST" />
        <ResourceContext.Provider value={SiteResourcesEnum.StatusesV2Latest}>
          <StatusesSimpleList mode="problematic" />
        </ResourceContext.Provider>
      </Card>
      <Card sx={{ p: 2, m: 2 }}>
        <CardHeader subheader="Statuses V2" />
        <ResourceContext.Provider value={SiteResourcesEnum.StatusesV2}>
          <StatusesSimpleList mode="classic" />
        </ResourceContext.Provider>
      </Card>
    </Stack>
  );
};

export default StatusList;
