import {
  AutocompleteArrayInput,
  Datagrid,
  DeleteButton,
  List,
  ListActions,
  ReferenceArrayInput,
  ReferenceField,
  SelectInput,
  TextField,
} from "react-admin";
import CustomDateField from "shared/components/fields/CustomDateField";
import { RteCommunicationV2ResourcesEnum, SiteResourcesEnum } from "shared/enums/resourceMappings";
import RteCommunicationMarketConfigCreate from "./RteCommunicationMarketConfigCreate";
import RteCommunicationMarketConfigEdit from "./RteCommunicationMarketConfigEdit";
import { useMemo } from "react";
import { rteCommunicationMarketStatusChoices, rteCommunicationMarketTypeChoices } from "shared/enums/reactAdminChoices";
import { FranceGroupTypeEnum } from "shared/enums/globalEnums";
import { GenericColorChip } from "shared/components/chips/GenericColorChip";
import {
  RteCommunicationMarketStatusColorMapping,
  RteCommunicationMarketTypeColorMapping,
} from "shared/enums/colorMappings/globalColorMappings";

const RteCommunicationMarketConfigList = () => {
  const filters = useMemo(
    () => [
      <ReferenceArrayInput
        label="Market configurations"
        source="id__in"
        reference={RteCommunicationV2ResourcesEnum.RteCommunicationMarketConfig}
        key="id__in"
      >
        <AutocompleteArrayInput label="Market configurations" sx={{ minWidth: 200 }} />
      </ReferenceArrayInput>,
      <ReferenceArrayInput
        label="EDRs"
        source="edr_id__in"
        reference={SiteResourcesEnum.Groups}
        key="edr_id__in"
        filter={{ type: FranceGroupTypeEnum.rte_edr }}
      >
        <AutocompleteArrayInput label="EDRs" />
      </ReferenceArrayInput>,
      <SelectInput label="Markets" source="market__in" key="market__in" choices={rteCommunicationMarketTypeChoices} />,
      <SelectInput
        label="Statuses"
        source="status__in"
        key="status__in"
        choices={rteCommunicationMarketStatusChoices}
      />,
    ],
    [],
  );

  return (
    <>
      <List actions={<ListActions hasCreate />} filters={filters}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="id" />
          <ReferenceField source="edr_id" reference={SiteResourcesEnum.Groups} />
          <GenericColorChip source="market" colorMapping={RteCommunicationMarketTypeColorMapping} />
          <GenericColorChip source="status" colorMapping={RteCommunicationMarketStatusColorMapping} />
          <CustomDateField showTime source="created_at" />
          <CustomDateField showTime source="updated_at" />
          <DeleteButton />
        </Datagrid>
      </List>
      <RteCommunicationMarketConfigCreate />
      <RteCommunicationMarketConfigEdit />
    </>
  );
};

export default RteCommunicationMarketConfigList;
