// Optimizer Country
export enum OptimizerCountryEnum {
  FR = "FR",
  FI = "FI",
}

// Optimizer Direction
export enum OptimizerDirectionEnum {
  UPWARD = "UPWARD",
  DOWNWARD = "DOWNWARD",
  SYMMETRIC = "SYMMETRIC",
}

// Optimizer Certifications Tests Type
export enum OptimizerCertificationsTestsTypeEnum {
  FCR_TEST_8H = "fcr_test_8h",
  AFRR_TEST_8H = "afrr_test_8h",
  AFRR_FCR_TEST_8H = "afrr_fcr_test_8h",
  AFRR_CHRONICLES = "afrr_chronicles",
  AFRR_CHRONICLE_1 = "afrr_chronicle_1",
  AFRR_CHRONICLE_2 = "afrr_chronicle_2",
}

// Optimizer Market
export enum OptimizerMarketEnum {
  AFRR = "AFRR",
  FCR = "FCR",
  AFRR_FCR = "AFRR_FCR",
  TOP_NIVEAU = "TOP_NIVEAU",
  SPOT = "SPOT",
}

// Optimizer Certification Status
export enum OptimizerCertificationStatusEnum {
  CERTIFIED = "CERTIFIED",
  TRIAL = "TRIAL",
}

// Optimizer Group Type
export enum OptimizerGroupTypeEnum {
  RTE_EDP = "rte_edp",
  RTE_EDR = "rte_edr",
  RTE_EDA = "rte_eda",
  FINGRID_RO = "fingrid_ro",
  FINGRID_PE = "fingrid_pe",
}

export enum OptiTaskStatusEnum {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  PENDING = "PENDING",
  STARTED = "STARTED",
}
