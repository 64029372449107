export enum SiteFrenchDsoEnum {
  enedis = "enedis",
  geredis = "geredis",
}

export enum SiteFinlandDsoEnum {
  elenia = "elenia",
  caruna = "caruna",
  savon_voima = "savon_voima",
  jse = "jse",
  kymenlaakson = "kymenlaakson",
  pks = "pks",
}

export enum allDsoEnum {
  enedis = "enedis",
  geredis = "geredis",
  elenia = "elenia",
  caruna = "caruna",
  savon_voima = "savon_voima",
  jse = "jse",
  kymenlaakson = "kymenlaakson",
  pks = "pks",
}

export enum SiteContryEnum {
  Finland = "Finland",
  France = "France",
}

export enum SiteOperatorEnum {
  NW_JMET = "NW JMET",
  NW_JMET2 = "NW JMET2",
  NW_JMET3 = "NW JMET3",
  NWJ_FIN_Oy = "NWJ FIN Oy",
}

export enum SiteFranceSupplierEnum {
  NWRE = "NWRE",
  ELMY = "ELMY",
}

export enum SiteFinlandSupplierEnum {
  NWRE = "NWRE",
  Entelios = "Entelios",
}

export enum RteComputationVersionEnum {
  soc_reserve = 1,
  t_tenue = 2,
}

export enum ChronicleFilenamesEnum {
  essai_1bis = "essai_1bis.csv",
  essai_2v2 = "essai_2v2.csv",
  essai_3v2 = "essai_3v2.csv",
  essai_4v2 = "essai_4v2.csv",
  essai_5 = "essai_5.csv",
  r2_essai_2_file1 = "r2_essai_2_file1.csv",
  r2_essai_2_file2 = "r2_essai_2_file2.csv",
  r2_essai_1_file1 = "r2_essai_1_file1.csv",
  r2_essai_1_file2 = "r2_essai_1_file2.csv",
  fr_performance_8_racks_jbox1_17h = "fr_performance_8_racks_jbox1_17h.csv",
  fr_performance_8_racks_jbox1_20h30 = "fr_performance_8_racks_jbox1_20h30.csv",
  fr_performance_8_racks_jbox1_21h = "fr_performance_8_racks_jbox1_21h.csv",
  fr_performance_8_racks_jbox1_21h_v2 = "fr_performance_8_racks_jbox1_21h_v2.csv",
  fr_performance_10_racks_jbox1_18h = "fr_performance_10_racks_jbox1_18h.csv",
  fr_performance_10_racks_jbox1_21h = "fr_performance_10_racks_jbox1_21h.csv",
  fr_performance_10_racks_jbox1_21h30 = "fr_performance_10_racks_jbox1_21h30.csv",
  fr_performance_10_racks_jbox1_21h30_v2 = "fr_performance_10_racks_jbox1_21h30_v2.csv",
  fr_msi_jbox2_43h40 = "fr_msi_jbox2_43h40.csv",
  fr_msi_jbox2_27h15 = "fr_msi_jbox2_27h15.csv",
  fin_msi_roundtrip_pilote_project_29h = "fin_msi_roundtrip_pilote_project_29h.csv",
  fin_certification_fingrid_fcr_n_test_1_485min = "fin_certification_fingrid_fcr-n_test_1_485min.csv",
  fin_certification_fingrid_fcr_n_test_1_515min = "fin_certification_fingrid_fcr-n_test_1_515min.csv",
  fin_certification_fingrid_fcr_n_test_2_22min = "fin_certification_fingrid_fcr-n_test_2_22min.csv",
  fin_certification_fingrid_fcr_n_sin10_300_1h20 = "fin_certification_fingrid_fcr-n_sin10-300_1h20.csv",
}

export enum PlanningModeEnum {
  soc_management = "soc_management",
  fcr_rte_v2 = "fcr_rte_v2",
  fcr_rte_v2_no_reserve = "fcr_rte_v2_no_reserve",
  afrr_rte = "afrr_rte",
  afrr_fcr_rte = "afrr_fcr_rte",
  power_chronicle = "power_chronicle",
  power = "power",
  stop = "stop",
  fingrid_fcrn = "fingrid_fcrn",
}

export enum FrancePlanningModeEnum {
  soc_management = "soc_management",
  fcr_rte_v2 = "fcr_rte_v2",
  fcr_rte_v2_no_reserve = "fcr_rte_v2_no_reserve",
  afrr_rte = "afrr_rte",
  afrr_fcr_rte = "afrr_fcr_rte",
  power_chronicle = "power_chronicle",
  power = "power",
  stop = "stop",
}

export enum FinlandPlanningModeEnum {
  soc_management = "soc_management",
  power_chronicle = "power_chronicle",
  power = "power",
  stop = "stop",
  fingrid_fcrn = "fingrid_fcrn",
}

export enum FrancePlanningMaskModeEnum {
  r2_tests = "r2_tests",
  r2_chronicles = "r2_chronicles",
  r1_chronicles = "r1_chronicles",
  enspired = "enspired",
  r1_r2_mask = "r1_r2_mask",
  technical_chronicle = "technical_chronicle",
  arbitrage_spot = "arbitrage_spot",
  fake_box_staging = "fake_box_staging",
  standby_france = "standby_france",
}

export enum FinlandPlanningMaskModeEnum {
  fcrn_standalone = "fcrn_standalone",
  fcrd_standalone = "fcrd_standalone",
  fcrd_fcrn_combined = "fcrd_fcrn_combined",
  r2_finland = "r2_finland",
  standby_finland = "standby_finland",
}

export enum FranceGroupTypeEnum {
  rte_edr = "rte_edr",
  rte_edp = "rte_edp",
  rte_edc = "rte_edc",
  rte_eda = "rte_eda",
}
export enum FinlandGroupTypeEnum {
  fingrid_pe = "fingrid_pe",
  fingrid_ro = "fingrid_ro",
}

export enum GroupTypeEnum {
  rte_edr = "rte_edr",
  rte_edp = "rte_edp",
  rte_edc = "rte_edc",
  rte_eda = "rte_eda",
  fingrid_pe = "fingrid_pe",
  fingrid_ro = "fingrid_ro",
}

export enum FormattedGroupTypeEnum {
  rte_edr = "EDR",
  rte_edp = "EDP",
  rte_edc = "EDC",
  rte_eda = "EDA",
  fingrid_pe = "FIN_PE",
  fingrid_ro = "FIN_RO",
}

export enum GroupModeEnum {
  prod = "prod",
  test = "test",
}

export enum MaintenanceTypeEnum {
  Enedis = "enedis",
  Preventive = "preventive",
  Fortuit = "fortuit",
  RE = "re",
}

export enum SiteModeEnum {
  test = "test",
  factory_trials = "factory_trials",
  operational_trials = "operational_trials",
  tso_trials = "tso_trials",
  production = "production",
}

export enum DirectionEnum {
  UPWARD = "UPWARD",
  DOWNWARD = "DOWNWARD",
}

export enum AfrrPointStatusEnum {
  NOT_SENT = "NOT_SENT",
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  PARTIALLY_ACCEPTED = "PARTIALLY_ACCEPTED",
  OBSOLETE = "OBSOLETE",
  REFUSED = "REFUSED",
  REJECTED = "REJECTED",
}

export enum TopNiveauGateStatusEnum {
  NOT_SENT = "NOT_SENT",
  PENDING = "PENDING",
  ACKNOWLEDGED = "ACKNOWLEDGED",
  PARTIALLY_ACKNOWLEDGED = "PARTIALLY_ACKNOWLEDGED",
  OBSOLETE = "OBSOLETE",
  REFUSED = "REFUSED",
}

export enum TITypeEnum {
  P_PROD = "P.PROD",
  PART_FSM = "PART.FSM",
  F_PROD = "F.PROD",
  PC = "PC",
  SOC_EDR = "SOC.EDR",
  RSFP = "RSFP",
  N_RSFP = "N.RSFP",
  RET_RSFP = "RET.RSFP",
}

export enum PacketTypeEnum {
  TM = "TM",
  TS = "TS",
  TVC = "TVC",
}

export enum RteCommunicationMarketTypeEnum {
  fcr = "fcr",
  afrr = "afrr",
}

export enum RteCommunicationMarketStatusEnum {
  enabled = "enabled",
  disabled = "disabled",
}

export enum RteCommunicationTestConfigTypeEnum {
  nlevel_loss = "nlevel_loss",
}
