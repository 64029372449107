import { useMemo } from "react";
import { Datagrid, EditButton, List, SelectInput, ShowButton, TextField, TextInput } from "react-admin";
import CustomDateField from "shared/components/fields/CustomDateField";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { BIDS_CURRENCY, BIDS_PRODUCT_NAME, BIDS_ZONES, MARKET } from "shared/enums";
import { getSelectOptions } from "shared/utils";

export const BidsList = () => {
  const productNameOptions = useMemo(() => getSelectOptions(BIDS_PRODUCT_NAME), []);
  const marketOptions = useMemo(() => getSelectOptions(MARKET), []);
  const currencyOptions = useMemo(() => getSelectOptions(BIDS_CURRENCY), []);
  const zoneOptions = useMemo(() => getSelectOptions(BIDS_ZONES), []);

  const bidsFilters = useMemo(
    () => [
      <SelectInput key="product_name" label="Product Name" source="product_name" choices={productNameOptions} />,
      <TextInput key="status" label="Status" source="status" />,
      <CustomDateTimeInput key="delivery_date" label="Delivery Date" source="delivery_date" />,
      <TextInput key="accepted_quantity" label="Accepted Quantity" source="accepted_quantity" />,
      <TextInput key="accepted_quantity__gt" label="Accepted Quantity Greater Than" source="accepted_quantity__gt" />,
      <SelectInput
        key="accepted_quantity__isnull"
        label="Accepted Quantity Is Null"
        source="accepted_quantity__isnull"
        choices={[
          { id: true, name: "Yes" },
          { id: false, name: "No" },
        ]}
      />,
      <TextInput key="offered_quantity" label="Offered Quantity" source="offered_quantity" />,
      <TextInput key="accepted_price" label="Accepted Price" source="accepted_price" />,
      <TextInput key="offered_price" label="Offered Price" source="offered_price" />,
      <TextInput key="country_code" label="Country Code" source="country_code" />,

      <TextInput key="regel_bid_id" label="Regel Bid ID" source="regel_bid_id" />,
      <SelectInput key="market" label="Market" source="market" choices={marketOptions} />,
      <TextInput key="deposit_operator" label="Deposit Operator" source="deposit_operator" />,
      <SelectInput key="currency" label="Currency" source="currency" choices={currencyOptions} />,
      <TextInput key="tso" label="TSO" source="tso" />,
      <SelectInput key="zone" label="Zone" source="zone" choices={zoneOptions} />,
    ],
    [currencyOptions, marketOptions, productNameOptions, zoneOptions],
  );

  return (
    <List filters={bidsFilters}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="regel_bid_id" />
        <TextField source="product_name" />
        <TextField source="status" />
        <CustomDateField source="delivery_date" showTime />
        <TextField source="accepted_quantity" />
        <TextField source="offered_quantity" />
        <TextField source="accepted_price" />
        <TextField source="offered_price" />
        <TextField source="currency" />
        <TextField source="country_code" />
        <TextField source="tso" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
