import { useMemo } from "react";
import { Datagrid, List, ReferenceField, SelectInput, TextField, TextInput } from "react-admin";
import { GenericColorChip } from "shared/components/chips/GenericColorChip";
import CustomDateField from "shared/components/fields/CustomDateField";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { OptimizerCertificationsTestsTypeColorMapping } from "shared/enums/colorMappings/optiColorMappings";
import { optimizerCertificationsTestsTypeChoices } from "shared/enums/reactAdminChoices";
import { OptimizerResourcesEnum } from "shared/enums/resourceMappings";
// import { optimizerCertificationsTestsTypeChoices } from "../../../shared/enums/opti_enums";

export default function CertificationsTestsList() {
  const CertificationsTestsFilters = useMemo(
    () => [
      <CustomDateTimeInput key="start_date__lt" label="Start date before" source="start_date__lt" />,
      <CustomDateTimeInput key="start_date__gt" label="Start date after" source="start_date__gt" />,
      <CustomDateTimeInput key="end_date__lt" label="End date before" source="end_date__lt" />,
      <CustomDateTimeInput key="end_date__gt" label="End date after" source="end_date__gt" />,
      <CustomDateTimeInput key="start_date" label="start_date" source="start_date" />,
      <CustomDateTimeInput key="end_date" label="end_date" source="end_date" />,
      <TextInput key="fcr_engagement" label="FCR engagement" source="fcr_engagement" />,
      <TextInput key="afrr_engagement" label="AFRR engagement" source="afrr_engagement" />,
      <TextInput key="group_code" label="Code EDP (rte_edp code)" source="group_code" />,
      <SelectInput
        key="test_type"
        label="Test type"
        source="test_type"
        choices={optimizerCertificationsTestsTypeChoices}
      />,
    ],
    [],
  );

  return (
    <List filters={CertificationsTestsFilters}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />
        <CustomDateField showTime source="start_date" />
        <CustomDateField showTime source="end_date" />
        <TextField source="fcr_engagement" />
        <TextField source="afrr_engagement" />
        <TextField source="group_code" label={"Code EDP (rte_edp code)"} />
        <ReferenceField
          source="certif_id"
          reference={OptimizerResourcesEnum.OptiCertifications}
          label={"Certification"}
        />
        <GenericColorChip source="test_type" colorMapping={OptimizerCertificationsTestsTypeColorMapping} />
      </Datagrid>
    </List>
  );
}
