import { NumberField, Show, SimpleShowLayout, TextField } from "react-admin";
import CustomDateField from "shared/components/fields/CustomDateField";

export const AfrrEngagementBidTimeserieShow = () => (
  <Show>
    <SimpleShowLayout>
      <CustomDateField showTime source="created_at" />
      <CustomDateField showTime source="updated_at" />
      <CustomDateField showTime source="start_date" />
      <CustomDateField showTime source="end_date" />
      <TextField source="direction" />
      <NumberField source="offered_quantity" />
      <NumberField source="offered_price" />
      <TextField source="divisible" />
      <TextField source="exclusivity_group" />
      <TextField source="symetrical_feature" />
      <TextField source="mrid" />
      <TextField source="id" />
      <TextField source="gate_document_id" label="Gate Document ID" />
    </SimpleShowLayout>
  </Show>
);
