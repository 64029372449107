export enum EcpBidBusinessTypeEnum {
  FCR_N = "fcr_n",
  FCR_D = "fcr_d",
}

export enum EcpBidStatusEnum {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  PARTIALLY_ACCEPTED = "PARTIALLY_ACCEPTED",
  REFUSED = "REFUSED",
}

export enum EcpBidDirectionEnum {
  UPWARD = "UPWARD",
  DOWNWARD = "DOWNWARD",
  SYMMETRIC = "SYMMETRIC",
}
