import { ChipField, ChipFieldProps, useRecordContext } from "react-admin";

import { RaRecord } from "react-admin";

interface GenericColorChipProps<T extends RaRecord, K extends keyof T> extends Omit<ChipFieldProps, "source"> {
  source: K;
  colorMapping: Record<T[K] & string, string>;
  defaultColor?: string;
}

export function GenericColorChip<T extends RaRecord, K extends keyof T>({
  source,
  colorMapping,
  defaultColor = "rgba(0, 0, 0, 0.08)",
  ...rest
}: GenericColorChipProps<T, K>) {
  const record = useRecordContext<T>();
  const value = record?.[source] as T[K] & string;
  const bgcolor = (value && colorMapping[value as keyof typeof colorMapping]) || defaultColor;

  return (
    <ChipField
      source={source as string}
      sx={{
        bgcolor,
      }}
      {...rest}
    />
  );
}
