import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  FunctionField,
  Identifier,
  List,
  RaRecord,
  ReferenceInput,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  useListContext,
} from "react-admin";
import { GenericColorChip } from "shared/components/chips/GenericColorChip";
import { FormattedGroupTypeEnum, GroupTypeEnum } from "shared/enums/globalEnums";
import { groupModeChoices } from "shared/enums/reactAdminChoices";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import { GroupeModeColorMapping } from "shared/enums/colorMappings/globalColorMappings";
import PlanningMaskField from "../PlanningMaskField";
import GroupFilterSidebar from "./GroupFilterSidebar";
import SiteNumberTextField from "./SiteNumberTextField";
import SiteTable from "./SiteTable";

export const GroupList = () => {
  const groupFilters = [
    <ReferenceInput source="id__in" reference={SiteResourcesEnum.Groups} key="id__in" label="name" alwaysOn autoFocus>
      <AutocompleteInput
        optionText={({ id, name, code }: { id: Identifier; name: string; code: string }) => {
          return `${id} - ${name} - ${code}`;
        }}
        label="name"
        autoFocus
      />
    </ReferenceInput>,
    <TextInput label="Code" source="code" key="code" />,
    <TextInput label="Code DSO" source="code_dso" key="code_dso" />,
    <SelectInput label="Mode" source="mode" key="mode" choices={groupModeChoices} />,
  ];

  return (
    <List
      filters={groupFilters}
      filterDefaultValues={{ type: GroupTypeEnum.rte_edr }}
      aside={<GroupFilterSidebar />}
      sx={{
        flexDirection: "column",
      }}
      perPage={50}
    >
      <TypeDependantDatagrid />
    </List>
  );
};

const TypeDependantDatagrid = () => {
  const { filterValues } = useListContext();
  const groupType = filterValues?.type;
  const codeLabel =
    groupType === undefined
      ? "Group Code"
      : `Code ${FormattedGroupTypeEnum[filterValues.type as keyof typeof FormattedGroupTypeEnum]}`;

  const getColumns = () => {
    const commonColumns = [
      <TextField source="name" key="name" />,
      <TextField source="code" sortable={false} key="code" label={codeLabel} />,
      <FunctionField
        key="site_number"
        label="Number of sites"
        render={(record: RaRecord) => <SiteNumberTextField record={record} />}
        sortable={false}
      />,
    ];

    const buttonColumns = [<ShowButton key="show" />, <EditButton key="edit" />];

    if (groupType === GroupTypeEnum.rte_edc) {
      return [...commonColumns, ...buttonColumns];
    }
    return [
      ...commonColumns,
      <GenericColorChip source="mode" sortable={false} key="mode" colorMapping={GroupeModeColorMapping} />,
      <PlanningMaskField source="current_planning_mask" key="planning_mask" />,
      ...buttonColumns,
    ];
  };

  const columns = getColumns();

  if (!columns) return null;

  return (
    <Datagrid rowClick="expand" bulkActionButtons={false} expand={<SiteTable />} expandSingle>
      {columns}
    </Datagrid>
  );
};
