import { CreateDialog } from "@react-admin/ra-form-layout";
import { useMemo } from "react";
import {
  AutocompleteArrayInput,
  Datagrid,
  DeleteButton,
  List,
  ListActions,
  NumberInput,
  RaRecord,
  ReferenceArrayInput,
  SelectArrayInput,
  TextField,
} from "react-admin";
import { GenericColorChip } from "shared/components/chips/GenericColorChip";
import CustomDateField from "shared/components/fields/CustomDateField";
import { tiTypeChoices } from "shared/enums/reactAdminChoices";
import { RteCommunicationV2ResourcesEnum } from "shared/enums/resourceMappings";
import { PacketTypeColorMapping, TITypeColorMapping } from "shared/enums/colorMappings/globalColorMappings";
import RteCommunicationConfigCreate from "./RteCommunicationConfigCreate";

const RteCommunicationConfigList = () => {
  const optionRenderer = (choice: RaRecord) => {
    console.log("choice:", choice);
    return `${choice.code_tm} ${choice.telemetry_field_name} `;
  };

  const filters = useMemo(
    () => [
      <ReferenceArrayInput
        label="Tranches TM"
        source="id__in"
        reference={RteCommunicationV2ResourcesEnum.RteCommunicationConfig}
        key="id__in"
        perPage={100}
      >
        <AutocompleteArrayInput label="Tranches TM" sx={{ minWidth: 300 }} optionText={optionRenderer} />
      </ReferenceArrayInput>,
      <NumberInput label="IOA" source="ioa" key={"ioa"} />,
      <SelectArrayInput
        label="Telemetry field names"
        source="telemetry_field_name__in"
        key="telemetry_field_name__in"
        choices={tiTypeChoices}
        sx={{ minWidth: 200 }}
      />,
    ],
    [],
  );

  return (
    <>
      <List actions={<ListActions hasCreate />} filters={filters}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="ioa" />
          <TextField source="code_tm" />
          <GenericColorChip source="packet_type" colorMapping={PacketTypeColorMapping} />

          <GenericColorChip source="telemetry_field_name" colorMapping={TITypeColorMapping} />
          <CustomDateField showTime source="created_at" />
          <CustomDateField showTime source="updated_at" />
          <DeleteButton />
        </Datagrid>
      </List>
      <CreateDialog fullWidth maxWidth="sm">
        <RteCommunicationConfigCreate />
      </CreateDialog>
    </>
  );
};

export default RteCommunicationConfigList;
