import {
  OptimizerCertificationStatusEnum,
  OptimizerCertificationsTestsTypeEnum,
  OptimizerCountryEnum,
  OptimizerDirectionEnum,
  OptimizerGroupTypeEnum,
  OptimizerMarketEnum,
  OptiTaskStatusEnum,
} from "../opti_enums";

// Optimizer Country
export const OptimizerCountryColorMapping: Record<OptimizerCountryEnum, string> = {
  [OptimizerCountryEnum.FR]: "#BBDEFB", // Pastel Light Blue
  [OptimizerCountryEnum.FI]: "#FFF9C4", // Pastel Cyan
};

// Optimizer Direction
export const OptimizerDirectionColorMapping: Record<OptimizerDirectionEnum, string> = {
  [OptimizerDirectionEnum.UPWARD]: "#C8E6C9", // Pastel Green
  [OptimizerDirectionEnum.DOWNWARD]: "#FFCDD2", // Pastel Red
  [OptimizerDirectionEnum.SYMMETRIC]: "#FFF9C4", // Pastel Yellow
};

// Optimizer Certifications Tests Type
export const OptimizerCertificationsTestsTypeColorMapping: Record<OptimizerCertificationsTestsTypeEnum, string> = {
  [OptimizerCertificationsTestsTypeEnum.FCR_TEST_8H]: "#BBDEFB", // Pastel Blue
  [OptimizerCertificationsTestsTypeEnum.AFRR_TEST_8H]: "#FFECB3", // Pastel Amber
  [OptimizerCertificationsTestsTypeEnum.AFRR_FCR_TEST_8H]: "#C8E6C9", // Pastel Green
  [OptimizerCertificationsTestsTypeEnum.AFRR_CHRONICLES]: "#FFCDD2", // Pastel Pink
  [OptimizerCertificationsTestsTypeEnum.AFRR_CHRONICLE_1]: "#E1BEE7", // Pastel Lavender
  [OptimizerCertificationsTestsTypeEnum.AFRR_CHRONICLE_2]: "#FFE0B2", // Pastel Orange
};

// Optimizer Market
export const OptimizerMarketColorMapping: Record<OptimizerMarketEnum, string> = {
  [OptimizerMarketEnum.AFRR]: "#C8E6C9", // Pastel Green
  [OptimizerMarketEnum.FCR]: "#BBDEFB", // Pastel Blue
  [OptimizerMarketEnum.AFRR_FCR]: "#E1BEE7", // Pastel Lavender
  [OptimizerMarketEnum.TOP_NIVEAU]: "#FFF9C4", // Pastel Yellow
  [OptimizerMarketEnum.SPOT]: "#FFCDD2", // Pastel Pink
};

// Optimizer Certification Status
export const OptimizerCertificationStatusColorMapping: Record<OptimizerCertificationStatusEnum, string> = {
  [OptimizerCertificationStatusEnum.CERTIFIED]: "#C8E6C9", // Pastel Green
  [OptimizerCertificationStatusEnum.TRIAL]: "#FFE0B2", // Pastel Orange
};

// Optimizer Group Type
export const OptimizerGroupTypeColorMapping: Record<OptimizerGroupTypeEnum, string> = {
  [OptimizerGroupTypeEnum.RTE_EDP]: "#BBDEFB", // Pastel Blue
  [OptimizerGroupTypeEnum.RTE_EDR]: "#B2DFDB", // Pastel Teal
  [OptimizerGroupTypeEnum.RTE_EDA]: "#E1BEE7", // Pastel Lavender
  [OptimizerGroupTypeEnum.FINGRID_RO]: "#FFF9C4", // Pastel Yellow
  [OptimizerGroupTypeEnum.FINGRID_PE]: "#FFCDD2", // Pastel Pink
};

// Opti Task Status
export const OptiTaskStatusColorMapping: Record<OptiTaskStatusEnum, string> = {
  [OptiTaskStatusEnum.SUCCESS]: "#C8E6C9", // Pastel Green
  [OptiTaskStatusEnum.FAILURE]: "#FFCDD2", // Pastel Red
  [OptiTaskStatusEnum.PENDING]: "#FFF9C4", // Pastel Yellow
  [OptiTaskStatusEnum.STARTED]: "#BBDEFB", // Pastel Blue
};
