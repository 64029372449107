import { Create, Loading, useCreate, useNotify, useRedirect } from "react-admin";
import { FormattedGroupTypeEnum } from "shared/enums/globalEnums";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import GroupBaseForm from "./GroupBaseForm";

export const GroupCreate = () => {
  const [create, { isLoading }]: any = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();

  const handleSubmit = (values: any) => {
    const { country, group_number, site_ids, ...rest } = values; // eslint-disable-line
    const formattedGroupTypeV2 = FormattedGroupTypeEnum[rest.type as keyof typeof FormattedGroupTypeEnum];

    const data = {
      ...rest,
      name: `${formattedGroupTypeV2} ${group_number}`,
      site_ids: site_ids || [],
    };
    create(
      SiteResourcesEnum.Groups,
      { data },
      {
        onSuccess: () => {
          redirect("list", SiteResourcesEnum.Groups);
        },
        onError: (error: any) => {
          const message =
            (error?.body && Array.isArray(error.body) && error.body.length && error.body[0]?.message) ||
            "Les données envoyées ne sont pas valides";
          notify(message, {
            type: "error",
            autoHideDuration: 10000,
          });
        },
      },
    );
  };

  if (isLoading) return <Loading />;

  return (
    <Create>
      <GroupBaseForm handleSubmit={handleSubmit} isCreateMode />
    </Create>
  );
};
