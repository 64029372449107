import { FieldValues } from "react-hook-form";

import React from "react";

import TestFcrNInitialForm from "./TestFcrNInitialForm";
import TestFcrNPlanningsForm from "./TestFcrNPlanningsForm";

const TestFingridFcrN: React.FC = () => {
  const [initialData, setInitialData] = React.useState<FieldValues | null>(null);

  const handleInitialSubmit = (data: FieldValues) => {
    setInitialData(data);
  };

  return !initialData ? (
    <TestFcrNInitialForm onSubmit={handleInitialSubmit} />
  ) : (
    <TestFcrNPlanningsForm initialData={initialData} />
  );
};

export default TestFingridFcrN;
