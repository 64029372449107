import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import { Box, Typography } from "@mui/material";
import { ChangeEvent, useEffect } from "react";
import {
  AutocompleteArrayInput,
  FormDataConsumer,
  NumberInput,
  RaRecord,
  ReferenceArrayInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  maxValue,
  minValue,
  required,
} from "react-admin";
import CustomDeletedDialogToolbar from "shared/components/toolbars/CustomDeletedDialogToolbar";
import { GroupTypeEnum, SiteContryEnum } from "shared/enums/globalEnums";
import {
  finlandGroupTypeChoices,
  franceGroupTypeChoices,
  groupModeChoices,
  siteCountryChoices,
} from "shared/enums/reactAdminChoices";

import { useFormContext } from "react-hook-form";

type GroupBaseFormProps = {
  handleSubmit: (values: any) => void;
  isCreateMode?: boolean;
};

const GroupBaseForm = ({ handleSubmit, isCreateMode = false }: GroupBaseFormProps) => {
  return (
    <SimpleForm
      onSubmit={handleSubmit}
      mode="onBlur"
      shouldUnregister
      toolbar={
        <CustomDeletedDialogToolbar
          isCreateMode={isCreateMode}
          resource={SiteResourcesEnum.Groups}
          resourceKey={"Group"}
        >
          <SaveButton />
        </CustomDeletedDialogToolbar>
      }
    >
      <Box display={"grid"} gridTemplateColumns={"repeat(4, 25%)"} width={"100%"} columnGap={2} pr={6}>
        {isCreateMode ? (
          <>
            <NumberInput
              source="group_number"
              validate={required()}
              helperText="The name will be the addition of the type + the number (ex: EDR 99)"
            />

            <CountryInput />
          </>
        ) : (
          <TextInput source="name" validate={required()} />
        )}
        <SelectInput source="mode" choices={groupModeChoices} validate={required()} />
        <TextInput source="code" validate={required()} />
      </Box>

      <FormDataConsumer>
        {({ formData }) => {
          const { type, country, site_ids, code } = formData;
          const isEdr = type === GroupTypeEnum.rte_edr;
          const isFrance = country === SiteContryEnum.France;
          const isFinland = country === SiteContryEnum.Finland;
          const siteCount = site_ids?.length || 0;

          return (
            <Box>
              <ReferenceArrayInput
                source="site_ids"
                reference={SiteResourcesEnum.Sites}
                filter={{
                  country: country,
                }}
              >
                <AutocompleteArrayInput label={"Sites"} />
              </ReferenceArrayInput>

              <Box display={"flex"} columnGap={2}>
                {isFrance && <SelectInput source="type" choices={franceGroupTypeChoices} validate={required()} />}
                {isFinland && <SelectInput source="type" choices={finlandGroupTypeChoices} validate={required()} />}
                <CodeTmInput type={type} code={code} />
              </Box>
              {isEdr && (
                <>
                  {siteCount > 0 && (
                    <Typography variant="caption" display="block" gutterBottom>
                      Values have been computed on the expectation that each site contributes 1.25 MW. It corresponds to
                      the max/min power * 110% and are the values used as max/min values in the IEC module
                    </Typography>
                  )}
                  <Box display={"flex"} columnGap={2}>
                    <GroupDataInput
                      siteCount={siteCount}
                      isMin
                      source={"group_data.iec.p_min"}
                      label="Min Power (MW)"
                    />
                    <GroupDataInput siteCount={siteCount} source={"group_data.iec.p_max"} label="Max Power (MW)" />
                    <GroupDataInput
                      siteCount={siteCount}
                      isMin
                      source={"group_data.iec.pc_min"}
                      label="Min Restoration Power - PC (MW)"
                    />
                    <GroupDataInput
                      siteCount={siteCount}
                      source={"group_data.iec.pc_max"}
                      label="Max Restoration Power - PC (MW)"
                    />
                  </Box>
                </>
              )}
            </Box>
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  );
};

export default GroupBaseForm;

const CountryInput = () => {
  const { setValue } = useFormContext();
  const handleChange = (event: ChangeEvent<HTMLInputElement> | RaRecord) => {
    setValue("site_ids", "");
    setValue("country", event.target.value);
  };

  return <SelectInput source="country" choices={siteCountryChoices} validate={required()} onChange={handleChange} />;
};

type GroupDataInputProps = {
  siteCount: number;
  source: string;
  label: string;
  isMin?: boolean;
};

const GroupDataInput = ({ siteCount, source, label, isMin }: GroupDataInputProps) => {
  const { setValue } = useFormContext();
  const numberValidations = isMin ? maxValue(-1) : minValue(1);
  const BASE_CAPACITY = 1.25 * 1.1;

  useEffect(() => {
    const newValue = siteCount * BASE_CAPACITY;
    setValue(source, isMin ? -newValue : newValue);
  }, [siteCount, setValue, isMin, source, BASE_CAPACITY]);

  return <NumberInput source={source} label={label} validate={(required(), numberValidations)} />;
};

const CodeTmInput = ({ type, code }: { type: string; code: string }) => {
  const { setValue } = useFormContext();
  const isEdr = type === "rte_edr";
  const isEdp = type === "rte_edp";
  const isDisplayed = isEdr || isEdp;

  useEffect(() => {
    if (isEdp) {
      setValue("code_tm", code);
    }
  }, [code, setValue, isEdp]);

  return isDisplayed ? (
    <TextInput
      source="code_tm"
      label={"Code TM"}
      validate={isEdr ? required() : undefined}
      disabled={isEdp}
      defaultValue={code}
    />
  ) : (
    <></>
  );
};
