import { Datagrid, List, NumberField, TextField } from "react-admin";
import { GenericColorChip } from "shared/components/chips/GenericColorChip";
import CustomDateField from "shared/components/fields/CustomDateField";
import { dsoColorMapping } from "shared/enums/colorMappings/globalColorMappings";

export const EmaxHGateList = () => (
  <List>
    <Datagrid>
      <TextField source="id" />
      <NumberField source="emax_h_gate_id" label={"Emax H Gate ID"} />
      <TextField source="edc_code" />
      <CustomDateField source="end_date" showTime />
      <CustomDateField source="start_date" showTime />
      <NumberField source="quantity" />
      <GenericColorChip source="dso" colorMapping={dsoColorMapping} />
      <CustomDateField source="updated_at" showTime />
    </Datagrid>
  </List>
);
