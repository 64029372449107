import { Datagrid, List, NumberField, TextField } from "react-admin";
import CustomDateField from "shared/components/fields/CustomDateField";

export const FakePasList = () => (
  <List>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" label={"EDR code"} />
      <NumberField source="pas.r1_h.first_value" label={"R1 1rst value"} />
      <NumberField source="pas.r1_h.second_value" label={"R1 2nd value"} />
      <CustomDateField showTime source="pas.r1_h.timestamp" label={"R1 timestamp"} />
      <NumberField source="pas.r2_h.first_value" label={"R2 1rst value"} />
      <NumberField source="pas.r2_h.second_value" label={"R2 2nd value"} />
      <CustomDateField showTime source="pas.r2_h.timestamp" label={"R2 timestamp"} />
    </Datagrid>
  </List>
);
