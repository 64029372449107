import { Identifier } from "react-admin";

type Choice = {
  id: string;
  name: string;
};

type EnumObject = Record<string, string | number>;

export const arrayToChoices = (array: Identifier[]) =>
  array.map((item) => ({ id: item.toString(), name: item.toString() }));

export const enumToChoices = (enumObject: EnumObject): Choice[] => {
  return Object.entries(enumObject)
    .filter(([key]) => isNaN(Number(key))) // Filter out numeric keys
    .map(([key, value]) => ({
      id: value.toString(),
      name: key,
    }));
};

export const multipleEnumsToChoices = (enumObjects: EnumObject[]) => {
  return enumObjects.reduce<Choice[]>((acc, enumObject) => {
    return acc.concat(enumToChoices(enumObject));
  }, []);
};

export const enumToValueArray = (enumObject: object) => {
  return (
    Object.entries(enumObject)
      .filter(([key]) => isNaN(Number(key))) // Filter out numeric keys
      // eslint-disable-next-line
      .map(([key, value]) => value)
  );
};
