import { addMinutes } from "date-fns";
import { FieldValues } from "react-hook-form";
import { ChronicleFilenamesEnum, PlanningModeEnum } from "shared/enums/globalEnums";

export enum FcrNPlanningEnum {
  planning_soc_1 = "planning_soc_1",
  planning_trial_1 = "planning_trial_1",
  planning_soc_2a = "planning_soc_2a",
  planning_trial_2a = "planning_trial_2a",
  planning_soc_2b = "planning_soc_2b",
  planning_trial_2b = "planning_trial_2b",
  planning_soc_3 = "planning_soc_3",
  planning_trial_3 = "planning_trial_3",
  planning_soc_1h = "planning_soc_1h",
  planning_trial_1h = "planning_trial_1h",
}

export enum TrialEngagementEnum {
  minimalOperationalPower = "minimalOperationalPower",
  maximalOperationalPower = "maximalOperationalPower",
}

export interface PlanningConfig {
  name: FcrNPlanningEnum;
  mode: PlanningModeEnum;
  durationInMinutes: number;
  engagement: TrialEngagementEnum | null;
  targetSoc: number | null;
  chronicleFilename: string | null;
}

export const planningConfigurations: PlanningConfig[] = [
  {
    name: FcrNPlanningEnum.planning_soc_1,
    mode: PlanningModeEnum.soc_management,
    durationInMinutes: 50,
    engagement: null,
    targetSoc: 0.5,
    chronicleFilename: null,
  },
  {
    name: FcrNPlanningEnum.planning_trial_1,
    mode: PlanningModeEnum.fingrid_fcrn,
    durationInMinutes: 515,
    engagement: TrialEngagementEnum.maximalOperationalPower,
    targetSoc: null,
    chronicleFilename: ChronicleFilenamesEnum.fin_certification_fingrid_fcr_n_test_1_515min,
  },
  {
    name: FcrNPlanningEnum.planning_soc_2a,
    mode: PlanningModeEnum.soc_management,
    durationInMinutes: 30,
    engagement: null,
    targetSoc: 0.5,
    chronicleFilename: null,
  },
  {
    name: FcrNPlanningEnum.planning_trial_2a,
    mode: PlanningModeEnum.fingrid_fcrn,
    durationInMinutes: 25,
    engagement: TrialEngagementEnum.minimalOperationalPower,
    targetSoc: null,
    chronicleFilename: ChronicleFilenamesEnum.fin_certification_fingrid_fcr_n_test_2_22min,
  },
  {
    name: FcrNPlanningEnum.planning_soc_2b,
    mode: PlanningModeEnum.soc_management,
    durationInMinutes: 20,
    engagement: null,
    targetSoc: 0.5,
    chronicleFilename: null,
  },
  {
    name: FcrNPlanningEnum.planning_trial_2b,
    mode: PlanningModeEnum.fingrid_fcrn,
    durationInMinutes: 25,
    engagement: TrialEngagementEnum.maximalOperationalPower,
    targetSoc: null,
    chronicleFilename: ChronicleFilenamesEnum.fin_certification_fingrid_fcr_n_test_2_22min,
  },
  {
    name: FcrNPlanningEnum.planning_soc_3,
    mode: PlanningModeEnum.soc_management,
    durationInMinutes: 30,
    engagement: null,
    targetSoc: 0.5,
    chronicleFilename: null,
  },
  {
    name: FcrNPlanningEnum.planning_trial_3,
    mode: PlanningModeEnum.fingrid_fcrn,
    durationInMinutes: 80,
    engagement: TrialEngagementEnum.minimalOperationalPower,
    targetSoc: null,
    chronicleFilename: ChronicleFilenamesEnum.fin_certification_fingrid_fcr_n_sin10_300_1h20,
  },
  {
    name: FcrNPlanningEnum.planning_soc_1h,
    mode: PlanningModeEnum.soc_management,
    durationInMinutes: 30,
    engagement: null,
    targetSoc: 0.5,
    chronicleFilename: null,
  },
  {
    name: FcrNPlanningEnum.planning_trial_1h,
    mode: PlanningModeEnum.fingrid_fcrn,
    durationInMinutes: 60,
    engagement: TrialEngagementEnum.maximalOperationalPower,
    targetSoc: null,
    chronicleFilename: null,
  },
];

export const computeDefaultValues = (initialData: FieldValues) => {
  return planningConfigurations.reduce((acc: Record<string, any>, currentConfig: PlanningConfig, index: number) => {
    const initialPlanning = index === 0;
    const prevPlanning = index > 0 ? planningConfigurations[index - 1] : null;
    const prevEndDate = prevPlanning ? acc[prevPlanning.name].end_date : null;

    const start_date = initialPlanning ? new Date(initialData.startDate) : prevEndDate;
    const end_date = addMinutes(start_date, currentConfig.durationInMinutes);
    const planningDefaults = {
      active: true,
      start_date,
      end_date,
      mode: currentConfig.mode,
      targetSoc: currentConfig.mode === PlanningModeEnum.soc_management ? currentConfig.targetSoc : null,
      chronicleFilename: currentConfig.mode === PlanningModeEnum.fingrid_fcrn ? currentConfig.chronicleFilename : null,
      engagement: null,
    };
    if (currentConfig.engagement) {
      planningDefaults.engagement =
        currentConfig.engagement === TrialEngagementEnum.minimalOperationalPower
          ? initialData[TrialEngagementEnum.minimalOperationalPower]
          : initialData[TrialEngagementEnum.maximalOperationalPower];
    }
    return { ...acc, [currentConfig.name]: planningDefaults };
  }, {});
};
