import { CreateDialog } from "@react-admin/ra-form-layout";
import { Datagrid, DeleteButton, List, ListActions, ReferenceField, TextField } from "react-admin";
import CustomDateField from "shared/components/fields/CustomDateField";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import RteCommunicationTestConfigCreate from "./RteCommunicationTestConfigCreate";

const RteCommunicationTestConfigList = () => {
  return (
    <>
      <List actions={<ListActions hasCreate />} empty={false}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" />
          <CustomDateField showTime source="start_date" />
          <CustomDateField showTime source="end_date" />
          <TextField source="type" />
          <ReferenceField source="group_id" reference={SiteResourcesEnum.Groups} />
          <CustomDateField showTime source="created_at" />
          <CustomDateField showTime source="updated_at" />
          <DeleteButton />
        </Datagrid>
      </List>
      <CreateDialog fullWidth maxWidth="sm">
        <RteCommunicationTestConfigCreate />
      </CreateDialog>
    </>
  );
};

export default RteCommunicationTestConfigList;
