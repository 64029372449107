import { useMemo } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  Datagrid,
  FunctionField,
  List,
  ListProps,
  RaRecord,
  ReferenceArrayInput,
  ReferenceField,
  TextField,
} from "react-admin";
import CustomBooleanField from "shared/components/fields/CustomBooleanField";
import CustomDateField from "shared/components/fields/CustomDateField";
import HumanReadableDurationField from "shared/components/fields/HumanReadableDurationField";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import StatusMaintenanceField from "./StatusMaintenanceField";

type ListMode = "classic" | "site" | "problematic";

interface StatusesSimpleListProps extends Partial<ListProps> {
  /**
   * List mode determines:
   * - Which filters are visible
   * - Which default filter values are applied
   * - Which columns are rendered
   *
   * "classic": All filters are editable.
   * "site": Fixed list for a site (site_id preset, no filters).
   * "problematic": A non-editable list with a default problematic filter.
   */
  mode?: ListMode;
}

export const StatusesSimpleList = ({ mode = "classic", ...rest }: StatusesSimpleListProps) => {
  const siteView = mode === "site";
  const classicView = mode === "classic";
  const problematicView = mode === "problematic";

  // Define filters based on mode. For problematic mode, we don't show filters.
  const filters = useMemo(() => {
    const classicFilters = [
      <BooleanInput
        key="online_rack_counts__isnull"
        label="Online Rack Counts Is Null"
        source="online_rack_counts__isnull"
      />,

      <CustomDateTimeInput key="ts__gt" label="Start Date >" source="ts__gt" />,
      <CustomDateTimeInput key="ts__lt" label="Start Date <" source="ts__lt" />,

      <BooleanInput key="no_fault" label="Fault" source="no_fault" />,
      <BooleanInput key="aggregator_mode" label="Aggregator Mode" source="aggregator_mode" />,
      <BooleanInput key="no_power_clamping" label="Power Clamping" source="no_power_clamping" />,
      <BooleanInput key="has_ems_com" label="EMS Com" source="has_ems_com" />,
      <BooleanInput key="has_data_com" label="Data Com" source="has_data_com" />,
    ];
    if (siteView) {
      // In site mode, we can't filter on site
      return classicFilters;
    }
    if (classicView) {
      const siteFilters = [
        <ReferenceArrayInput
          key="site_id__in"
          label="Sites"
          source="site_id__in"
          reference={SiteResourcesEnum.Sites}
          perPage={100}
          alwaysOn
        >
          <AutocompleteArrayInput label="Sites" sx={{ minWidth: 300 }} />
        </ReferenceArrayInput>,
      ];
      return [...siteFilters, ...classicFilters];
    }
    // In non-classic modes, filters are fixed and not editable.
    return undefined;
  }, [classicView, siteView]);

  // Set default filter values for modes that need fixed filters.
  const filterDefaultValues = useMemo(() => {
    if (mode === "problematic") {
      return { problematic: true };
    }
    return rest.filterDefaultValues || {};
  }, [mode, rest.filterDefaultValues]);

  // Use a unique storeKey per mode to isolate list parameters.
  const storeKey = useMemo(() => {
    switch (mode) {
      case "classic":
        return "StatusesSimpleList_classic";
      case "site":
        return "StatusesSimpleList_site";
      case "problematic":
        return "StatusesSimpleList_problematic";
      default:
        return "StatusesSimpleList";
    }
  }, [mode]);

  return (
    <List
      disableSyncWithLocation
      storeKey={storeKey}
      filters={filters}
      filterDefaultValues={filterDefaultValues}
      sort={{ field: "ts", order: "DESC" }}
      {...rest}
    >
      <Datagrid bulkActionButtons={false}>
        {!siteView && <ReferenceField source="site_id" reference={SiteResourcesEnum.Sites} />}
        <CustomDateField showTime source="ts" label="Start Date" />
        {!problematicView && <CustomDateField showTime source="end_date" sortable={false} />}
        <CustomBooleanField source="aggregator_mode" />
        <CustomBooleanField source="no_fault" label="Fault" />
        <CustomBooleanField source="no_power_clamping" label="Power clamping" />
        <CustomBooleanField source="has_data_com" label="Data" />
        <CustomBooleanField source="has_ems_com" label="EMS com" />
        <TextField source="online_rack_counts" label="Online Rack Count" />
        {classicView && <HumanReadableDurationField source="duration" sortable={false} />}
        {problematicView && (
          <FunctionField
            key="Maintenance Infos"
            label="Maintenance Infos"
            render={(record: RaRecord) => <StatusMaintenanceField record={record} />}
            sortable={false}
          />
        )}
      </Datagrid>
    </List>
  );
};
