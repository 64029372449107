import CircularProgress from "@mui/material/CircularProgress";
import { MaintenanceChip } from "entities/maintenances/components/MaintenanceChip";
import { useCallback, useEffect, useState } from "react";
import { RaRecord, SortPayload, useDataProvider } from "react-admin";
import { MaintenanceTypeEnum } from "shared/enums/globalEnums";
import { MaintenanceResourcesEnum } from "shared/enums/resourceMappings";
import { formatDatetimeString } from "shared/utils/helpers";

type MaintenanceType = MaintenanceTypeEnum | null;

const StatusMaintenanceField = ({ record }: { record: RaRecord }) => {
  const dataprovider = useDataProvider();
  const [maintenance, setmaintenance] = useState<any | null>(null); // TODO: replace any with the correct type
  const [loading, setloading] = useState(true);

  const getMaintenanceInfos = useCallback(() => {
    const maintenanceTypes: MaintenanceType[] = [
      MaintenanceTypeEnum.Enedis,
      MaintenanceTypeEnum.Preventive,
      MaintenanceTypeEnum.Fortuit,
    ];

    if (!maintenance || !maintenanceTypes.includes(maintenance.type)) {
      return <MaintenanceChip color="error" label="fortuit" />;
    }

    const label = `${maintenance.type} : ${formatDatetimeString(maintenance.start)} ➡ ${formatDatetimeString(
      maintenance.end,
    )}`;

    return <MaintenanceChip color="warning" label={label} />;
  }, [maintenance]);

  const fetchMaintenance = useCallback(async () => {
    const now = new Date();
    const { data } = await dataprovider.getList(MaintenanceResourcesEnum.Maintenance, {
      sort: { field: "start", order: "ASC" } as SortPayload,
      pagination: { page: 1, perPage: 1 },
      filter: {
        site_id: record.site_id,
        start__lte: now.toISOString(),
        end__gte: now.toISOString(),
      },
    });
    return data;
  }, [dataprovider, record.site_id]);

  useEffect(() => {
    setloading(true);
    fetchMaintenance()
      .then((data) => {
        setmaintenance(data.length ? data[0] : null);
        setloading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setloading(false);
      });
  }, [fetchMaintenance]);

  if (loading) return <CircularProgress />;

  return <span>{getMaintenanceInfos()}</span>;
};

export default StatusMaintenanceField;
