import FlagIcon from "@mui/icons-material/Flag";
import { Box } from "@mui/material";
import { useCallback } from "react";
import { ChipField, ChipFieldProps, useRecordContext } from "react-admin";
import { SiteContryEnum } from "shared/enums/globalEnums";
import FinlandFlagEmojiIcon from "../icons/flags/FinlandFlagEmojiIcon";
import FrenchFlagEmojiIcon from "../icons/flags/FrenchFlagEmojiIcon";

const CustomCountryChip = (props: ChipFieldProps) => {
  const record = useRecordContext();

  const getIcon = useCallback(() => {
    switch (record?.country) {
      case SiteContryEnum.Finland:
        return <FinlandFlagEmojiIcon />;
      case SiteContryEnum.France:
        return <FrenchFlagEmojiIcon />;

      default:
        return <FlagIcon />;
    }
  }, [record]);

  return <ChipField {...props} icon={<Box pl={1}>{getIcon()}</Box>} />;
};

export default CustomCountryChip;
