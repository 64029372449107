import { ChartData, ChartOptions } from "chart.js";
import { PointType } from "entities/affr_engagement_service/types";
import { format, isSameHour } from "date-fns";

export default function useBarChartPointsConfig(
  data: ChartData<"bar">,
  dateRange: { startDate: Date; endDate: Date },
  onBarClick: (start_date: Date) => void,
) {
  const options: ChartOptions<"bar"> = {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Stacked Points (click on a bar to see range details)",
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            const point = context[0]?.raw as PointType;
            const quantity = `${point.accepted_quantity ?? "-"} / ${point.offered_quantity ?? "-"}`;
            const price = `${point.accepted_price ?? "-"} / ${point.offered_price ?? "-"}`;
            const startDate = format(point.start_date as Date, "dd/MM/yyyy HH:mm");
            const endDate = format(point.end_date as Date, "dd/MM/yyyy HH:mm");
            return `• Status: ${point.status} \n• Start: ${startDate} \n• End: ${endDate} \n• Quantity: ${quantity} \n• Price: ${price} \n• Direction: ${point.direction}`;
          },
          label: function () {
            return "";
          },
        },
        padding: 10,
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        type: "time",
        time: {
          unit: "hour",
          displayFormats: {
            hour: "dd/MM/yyyy HH:mm",
          },
          tooltipFormat: "dd/MM/yyyy HH:mm",
        },
        grid: {
          color(ctx) {
            const tick = ctx.tick;
            if (!tick) {
              return "rgba(0,0,0,0.1)";
            }
            const tickValue = ctx.tick.value;
            const tickDate = new Date(tickValue);
            const now = new Date();

            // Check if the tick is in the current hour of today
            if (isSameHour(tickDate, now)) {
              return "green";
            } else if (tickDate.getHours() === 9 && tickDate.getMinutes() === 0) {
              return "red";
            } else if (tickDate.getHours() === 0 && tickDate.getMinutes() === 0) {
              return "rgba(0,0,0,0.5)";
            }
            return "rgba(0,0,0,0.1)";
          },
          lineWidth(ctx) {
            const tick = ctx.tick;
            if (!tick) {
              return 1;
            }
            const tickValue = ctx.tick.value;
            const tickDate = new Date(tickValue);
            const now = new Date();

            // Check if the tick is in the current hour of today
            if (isSameHour(tickDate, now) || (tickDate.getHours() === 9 && tickDate.getMinutes() === 0)) {
              return 2;
            }
            return 1;
          },
        },
        ticks: {
          source: "labels",
          autoSkip: false,
          stepSize: 1,
          font: {
            size: 10,
          },
          major: {
            enabled: true,
          },
        },
        min: dateRange.startDate.toISOString(),
        max: dateRange.endDate.toISOString(),
      },
      y: {
        title: {
          display: true,
          text: "Racoon",
          font: {
            size: 20,
          },
        },
        stacked: true,
        ticks: {
          callback: function (value) {
            return `${value} MW`;
          },
        },
        grid: {
          color(ctx) {
            const tick = ctx.tick;
            if (!tick) {
              return "rgba(0,0,0,0.1)";
            }
            const tickValue = ctx.tick.value;
            if (tickValue === 0) {
              return "rgba(0,0,0,0.5)";
            }
            return "rgba(0,0,0,0.1)";
          },
        },
        beginAtZero: true,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const { datasetIndex, index } = elements[0];
        const point = data.datasets[datasetIndex].data[index] as unknown as PointType;
        onBarClick(point.start_date as Date);
      }
    },
  };

  return { options };
}
