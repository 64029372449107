import {
  allDsoEnum,
  DirectionEnum,
  FinlandPlanningMaskModeEnum,
  FrancePlanningMaskModeEnum,
  GroupModeEnum,
  PacketTypeEnum,
  RteCommunicationMarketStatusEnum,
  RteCommunicationMarketTypeEnum,
  SiteOperatorEnum,
  TITypeEnum,
} from "shared/enums/globalEnums";
import { MaskModeType } from "shared/types";

export const maskModeColorMapping: Record<MaskModeType, string> = {
  // France modes
  [FrancePlanningMaskModeEnum.r2_tests]: "#EDF6F9", // Cultured (light pastel blue)
  [FrancePlanningMaskModeEnum.r2_chronicles]: "#FFDDD2", // Unbleached Silk (pastel peach)
  [FrancePlanningMaskModeEnum.r1_chronicles]: "#FFD8C2", // Pastel Burnt Sienna
  [FrancePlanningMaskModeEnum.enspired]: "#FFCDD2", // Pastel Rose
  [FrancePlanningMaskModeEnum.r1_r2_mask]: "#A8C8FF", // Pastel Royal Blue
  [FrancePlanningMaskModeEnum.technical_chronicle]: "#A0D2FF", // Pastel Star Command Blue
  [FrancePlanningMaskModeEnum.arbitrage_spot]: "#FFAB91", // Pastel Ruby Red
  [FrancePlanningMaskModeEnum.fake_box_staging]: "#FFE0B2", // Pastel Peach
  [FrancePlanningMaskModeEnum.standby_france]: "#A8DADC", // Pastel Blue-Green

  // Finland modes
  [FinlandPlanningMaskModeEnum.fcrn_standalone]: "#A7D7C5", // Pastel Teal
  [FinlandPlanningMaskModeEnum.fcrd_standalone]: "#C1B8D2", // Pastel Dark Slate
  [FinlandPlanningMaskModeEnum.fcrd_fcrn_combined]: "#FFF4C2", // Pastel Yellow
  [FinlandPlanningMaskModeEnum.r2_finland]: "#FFCCBC", // Pastel Red-Orange
  [FinlandPlanningMaskModeEnum.standby_finland]: "#8ECAE6", // Light Blue (pastel)
};

export const dsoColorMapping: Record<allDsoEnum, string> = {
  [allDsoEnum.caruna]: "#d98880", // Light blue
  [allDsoEnum.enedis]: "#7fb3d5", // Light blue
  [allDsoEnum.geredis]: "#c39bd3", // Light lavender
  [allDsoEnum.elenia]: "#f7dc6f", // Pastel yellow
  [allDsoEnum.savon_voima]: "#76d7c4", // Soft turquoise
  [allDsoEnum.jse]: "#aed6f1", // Pale sky blue
  [allDsoEnum.kymenlaakson]: "#27ae60", // Vivid green
  [allDsoEnum.pks]: "#d35400", // Warm deep orange
};

export const TITypeColorMapping: Record<TITypeEnum, string> = {
  [TITypeEnum.P_PROD]: "#BBDEFB", // Pastel Blue
  [TITypeEnum.PART_FSM]: "#FFE0B2", // Pastel Peach
  [TITypeEnum.F_PROD]: "#C8E6C9", // Pastel Green
  [TITypeEnum.PC]: "#D1C4E9", // Pastel Lavender
  [TITypeEnum.SOC_EDR]: "#FFF9C4", // Pastel Light Yellow
  [TITypeEnum.RSFP]: "#B2EBF2", // Pastel Cyan
  [TITypeEnum.N_RSFP]: "#FFCDD2", // Pastel Pink
  [TITypeEnum.RET_RSFP]: "#E1BEE7", // Pastel Mauve
};

export const PacketTypeColorMapping: Record<PacketTypeEnum, string> = {
  [PacketTypeEnum.TM]: "#BBDEFB", // Pastel Blue
  [PacketTypeEnum.TS]: "#C8E6C9", // Pastel Green
  [PacketTypeEnum.TVC]: "#FFE0B2", // Pastel Peach
};

export const RteCommunicationMarketTypeColorMapping: Record<RteCommunicationMarketTypeEnum, string> = {
  [RteCommunicationMarketTypeEnum.fcr]: "#B3E5FC", // Pastel Light Blue
  [RteCommunicationMarketTypeEnum.afrr]: "#FFECB3", // Pastel Amber
};

export const RteCommunicationMarketStatusColorMapping: Record<RteCommunicationMarketStatusEnum, string> = {
  [RteCommunicationMarketStatusEnum.enabled]: "#C8E6C9", // Pastel Green for enabled
  [RteCommunicationMarketStatusEnum.disabled]: "#FFCDD2", // Pastel Red for disabled
};

export const GroupeModeColorMapping: Record<GroupModeEnum, string> = {
  [GroupModeEnum.prod]: "info.main",
  [GroupModeEnum.test]: "warning.main",
};

export const DirectionColorMapping: Record<DirectionEnum, string> = {
  [DirectionEnum.UPWARD]: "#C8E6C9", // Pastel Green
  [DirectionEnum.DOWNWARD]: "#FFCDD2", // Pastel Red
};

export const siteOperatorColorMapping: Record<SiteOperatorEnum, string> = {
  [SiteOperatorEnum.NW_JMET]: "#BBDEFB", // Pastel light blue
  [SiteOperatorEnum.NW_JMET2]: "#C8E6C9", // Pastel light green
  [SiteOperatorEnum.NW_JMET3]: "#FFF9C4", // Pastel light yellow
  [SiteOperatorEnum.NWJ_FIN_Oy]: "#D1C4E9", // Pastel lavender
};
