import { EcpBidBusinessTypeEnum, EcpBidDirectionEnum, EcpBidStatusEnum } from "../ecpEnums";

// ecpBidBusinessTypeEnum mapping
export const ecpBidBusinessTypeColorMapping: Record<EcpBidBusinessTypeEnum, string> = {
  [EcpBidBusinessTypeEnum.FCR_N]: "#C8E6C9", // Pastel Green
  [EcpBidBusinessTypeEnum.FCR_D]: "#BBDEFB", // Pastel Blue
};

// EcpBidStatusEnum mapping
export const EcpBidStatusColorMapping: Record<EcpBidStatusEnum, string> = {
  [EcpBidStatusEnum.PENDING]: "#FFF9C4", // Pastel Yellow
  [EcpBidStatusEnum.ACCEPTED]: "#C8E6C9", // Pastel Green
  [EcpBidStatusEnum.PARTIALLY_ACCEPTED]: "#FFE0B2", // Pastel Orange
  [EcpBidStatusEnum.REFUSED]: "#FFCDD2", // Pastel Red
};

// EcpBidDirectionEnum mapping
export const EcpBidDirectionColorMapping: Record<EcpBidDirectionEnum, string> = {
  [EcpBidDirectionEnum.UPWARD]: "#C8E6C9", // Pastel Green
  [EcpBidDirectionEnum.DOWNWARD]: "#FFCDD2", // Pastel Red
  [EcpBidDirectionEnum.SYMMETRIC]: "#FFF9C4", // Pastel Yellow
};
