import { Button, SimpleForm, Toolbar, useRedirect } from "react-admin";
import { FieldValues } from "react-hook-form";

import React, { useMemo, useState } from "react";
import { PlanningResourceMapping, PlanningResourcesEnum } from "shared/enums/resourceMappings";
import { computeDefaultValues, PlanningConfig, planningConfigurations } from "./utils";

import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { useCallback } from "react";
import { PlanningType } from "shared/types";
import TestFcrNPlanningItem from "./TestFcrNPlanningItem";

export interface PlanningFormProps {
  initialData: FieldValues;
}

const TestFcrNPlanningsForm: React.FC<PlanningFormProps> = ({ initialData }) => {
  const defaultValues = useMemo(() => computeDefaultValues(initialData), [initialData]);
  const redirect = useRedirect();
  const [isLoading, setIsLoading] = useState(false);

  const prepareData = useCallback((values: FieldValues) => {
    return Object.values(values).reduce(
      (acc: PlanningType[], { chronicleFilename, end_date, start_date, engagement, mode, targetSoc, active }) => {
        if (!active) return acc;
        return [
          ...acc,
          {
            chronicle_filename: chronicleFilename,
            start_date,
            end_date,
            mode,
            target_soc: targetSoc,
            operator_id: 0,
            overidable: true,
            fcrn_engagement: engagement,
            reactivity_delay: 0,
          },
        ];
      },
      [],
    );
  }, []);

  const handleSubmit = useCallback(
    async (values: FieldValues) => {
      setIsLoading(true);
      const sitePlannings = prepareData(values);
      const preparedData = initialData.siteIds.reduce((acc: Record<number, PlanningType[]>, siteId: number) => {
        return {
          ...acc,
          [siteId]: sitePlannings,
        };
      }, {});
      try {
        const { status } = await axios({
          method: "post",
          url: `${process.env.REACT_APP_PLANNINGS_API_URL}/${PlanningResourceMapping[PlanningResourcesEnum.Plannings]}`,
          data: preparedData,
        });
        if (status >= 200 && status < 300) {
          setIsLoading(false);
          redirect(`/${PlanningResourcesEnum.Plannings}`);
        }
      } catch (error) {
        console.error("error:", error);
        setIsLoading(false);
      }
    },
    [prepareData, initialData, redirect],
  );

  return (
    <SimpleForm
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      toolbar={
        <Toolbar>
          <Button
            variant="contained"
            size="large"
            label="Submit plannings"
            disabled={isLoading}
            type="submit"
            startIcon={<SendIcon />}
          />
        </Toolbar>
      }
    >
      {planningConfigurations.map((config: PlanningConfig, index: number) => {
        return <TestFcrNPlanningItem key={config.name} config={config} index={index} initialData={initialData} />;
      })}
    </SimpleForm>
  );
};
export default TestFcrNPlanningsForm;
