import { NumberField, Show, TextField } from "react-admin";
import CustomDateField from "shared/components/fields/CustomDateField";

const TopNiveauGatesShow = () => (
  <Show>
    <TextField source="id" />
    <CustomDateField source="created_at" locales={"fr-FR"} showTime />
    <CustomDateField source="updated_at" locales={"fr-FR"} showTime />
    <CustomDateField source="target_gate" locales={"fr-FR"} showTime />
    <CustomDateField source="application_gate" locales={"fr-FR"} showTime />
    <CustomDateField source="deposit_date" locales={"fr-FR"} showTime />
    <TextField source="xml_file_link" />
    <NumberField source="revision_number" />
    <TextField source="status" />
    <TextField source="ticket_number" />
  </Show>
);

export default TopNiveauGatesShow;
