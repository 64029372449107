import { AutocompleteArrayInput, NumberInput, ReferenceArrayInput, required, SimpleForm } from "react-admin";
import { FieldValues } from "react-hook-form";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { SiteContryEnum } from "shared/enums/globalEnums";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import { validatePlanningTime } from "shared/utils/validations";
import { TrialEngagementEnum } from "./utils";

export interface InitialFormProps {
  onSubmit: (data: FieldValues) => void;
}

const TestFcrNInitialForm = ({ onSubmit }: InitialFormProps) => {
  return (
    <SimpleForm
      onSubmit={onSubmit}
      sx={{
        "& .MuiStack-root": {
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 2,
        },
      }}
    >
      <ReferenceArrayInput
        label="Sites"
        source="siteIds"
        reference={SiteResourcesEnum.Sites}
        filter={{ country: SiteContryEnum.Finland }}
        perPage={100}
      >
        <AutocompleteArrayInput label="Sites" />
      </ReferenceArrayInput>
      <CustomDateTimeInput source="startDate" validate={[required(), validatePlanningTime]} minDateTime={new Date()} />
      <NumberInput source={TrialEngagementEnum.minimalOperationalPower} defaultValue={0.1} validate={[required()]} />
      <NumberInput source={TrialEngagementEnum.maximalOperationalPower} defaultValue={0.927} validate={[required()]} />
    </SimpleForm>
  );
};

export default TestFcrNInitialForm;
