import { BooleanInput, NumberInput } from "react-admin";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";

import { PlanningModeEnum } from "shared/enums/globalEnums";
import { PlanningConfig, planningConfigurations } from "./utils";

import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { addMinutes, getDay } from "date-fns";
import { useCallback } from "react";
import { TextInput } from "react-admin";
import { FieldValues, useFormContext } from "react-hook-form";

interface PlanningItemProps {
  config: PlanningConfig;
  index: number;
  initialData: FieldValues;
}

const TestFcrNPlanningItem = ({ config, index, initialData }: PlanningItemProps) => {
  const { setValue, getValues } = useFormContext();
  const currentPlanning = getValues(config.name);
  const currentActive = currentPlanning.active;
  const isOverlappingDays = getDay(currentPlanning.start_date) !== getDay(currentPlanning.end_date);

  const getPreviousActivePlannning = useCallback(() => {
    let prevIndex = index - 1;
    while (prevIndex >= 0) {
      const prevConfig = planningConfigurations[prevIndex];
      const prevActive = getValues(`${prevConfig.name}.active`);
      if (prevActive) {
        return getValues(prevConfig.name);
      }
      prevIndex--;
    }
    return null;
  }, [index, getValues]);

  const prevPlanningValues = getPreviousActivePlannning();

  // Validator for start date
  const validateStartDate = useCallback(
    (value: Date | null) => {
      if (!value) return "Start date is required";
      const minAllowed = prevPlanningValues ? new Date(prevPlanningValues.end_date) : new Date();
      if (value < minAllowed) {
        return prevPlanningValues
          ? "Start date cannot be before the previous planning's end date"
          : "Start date cannot be in the past";
      }
      return undefined;
    },
    [prevPlanningValues],
  );

  // Recalculate the chain of plannings starting from changedIndex + 1.
  const recalcFollowingPlannings = useCallback(
    (changedIndex: number, newStart: Date) => {
      let currentStart = newStart;
      planningConfigurations.slice(changedIndex + 1).map((nextConfig) => {
        const active = getValues(`${nextConfig.name}.active`);
        if (active) {
          // Set the next planning's start_date and end_date.
          setValue(`${nextConfig.name}.start_date`, currentStart, { shouldDirty: true });
          const newEnd = addMinutes(currentStart, nextConfig.durationInMinutes);
          setValue(`${nextConfig.name}.end_date`, newEnd, { shouldDirty: true });
          currentStart = newEnd;
        }
      });
    },
    [getValues, setValue],
  );

  // Handler when the start date is changed.
  const handleStartDateChange = useCallback(
    (newDate: Date | null) => {
      if (!newDate) return;
      // Set current planning's start_date.
      setValue(`${config.name}.start_date`, newDate, { shouldDirty: true });
      // Compute new end_date based on the duration.
      const newEnd = addMinutes(newDate, config.durationInMinutes);
      setValue(`${config.name}.end_date`, newEnd, { shouldDirty: true });
      // Update all following active plannings.
      recalcFollowingPlannings(index, newEnd);
    },
    [config.name, config.durationInMinutes, index, recalcFollowingPlannings, setValue],
  );

  const handleActiveToggle = useCallback(
    (newActive: boolean) => {
      setValue(`${config.name}.active`, newActive, { shouldDirty: true });
      if (newActive) {
        // When activating: set current start to previous planning's end (or global start).
        let newStart: Date;
        if (prevPlanningValues) {
          newStart = prevPlanningValues.end_date;
        } else {
          newStart = initialData.startDate;
        }
        setValue(`${config.name}.start_date`, newStart, { shouldDirty: true });
        const newEnd = addMinutes(newStart, config.durationInMinutes);
        setValue(`${config.name}.end_date`, newEnd, { shouldDirty: true });
        recalcFollowingPlannings(index, newEnd);
      } else {
        // When deactivating: recalc the chain starting from the previous planning.
        let newStart: Date;
        if (prevPlanningValues) {
          newStart = new Date(prevPlanningValues.end_date);
        } else {
          newStart = new Date(initialData.startDate);
        }
        recalcFollowingPlannings(index - 1, newStart);
      }
    },
    [config.name, config.durationInMinutes, index, prevPlanningValues, recalcFollowingPlannings, setValue, initialData],
  );

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        width: "100%",
        backgroundColor: !currentActive ? "rgba(128,128,128,0.3)" : isOverlappingDays ? "rgba(255,0,0,0.2)" : "inherit",
      }}
    >
      <CardHeader
        title={config.name}
        subheader={`Durée: ${config.durationInMinutes} minutes`}
        action={
          <BooleanInput
            source={`${config.name}.active`}
            label="Actif"
            defaultValue={true}
            onChange={(e) => {
              const newVal = e.target.checked;
              handleActiveToggle(newVal);
            }}
          />
        }
      />
      <CardContent>
        <CustomDateTimeInput
          source={`${config.name}.start_date`}
          label="Start Date"
          onChange={handleStartDateChange}
          validate={validateStartDate}
          minDateTime={prevPlanningValues ? new Date(prevPlanningValues.end_date) : new Date()}
          disabled={!currentActive}
        />
        <CustomDateTimeInput
          source={`${config.name}.end_date`}
          label="End Date"
          readOnly
          sx={{
            "& .MuiInputBase-input": {
              bgcolor: "rgba(0, 0, 0, 0.08)",
              color: "GrayText",
            },
          }}
        />
        <TextInput source={`${config.name}.mode`} label="Mode" readOnly />
        {config.mode === PlanningModeEnum.soc_management && (
          <NumberInput source={`${config.name}.targetSoc`} label="SOC Cible" disabled={!currentActive} />
        )}
        {config.mode === PlanningModeEnum.fingrid_fcrn && (
          <>
            <NumberInput source={`${config.name}.engagement`} label="Engagement" readOnly />
            {config.chronicleFilename && (
              <TextInput source={`${config.name}.chronicleFilename`} label="Chronicle Filename" readOnly />
            )}
          </>
        )}
        {isOverlappingDays && (
          <Typography variant="caption" color="error">
            {"Attention : ce planning chevauche deux jours !"}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default TestFcrNPlanningItem;
