import { FieldProps, useFieldValue } from "react-admin";
import { getHumanReadableDuration } from "shared/utils/helpers";

const HumanReadableDurationField = (props: FieldProps) => {
  const value = useFieldValue(props);
  if (value == null) return <span>-</span>;

  return <span>{getHumanReadableDuration(value)}</span>;
};

export default HumanReadableDurationField;
